import React, { createContext, useContext, useRef, useState } from 'react';
import { Flipper, useTimeout, usePrompt, useClickOutside } from '@ocsoft/paper';

import styles from './alternator.css';

const AlternatorContext = createContext({ currentId: null, setCurrentId: () => { } });

// -----------------------------------------------------------------------------
//    Alternator
// -----------------------------------------------------------------------------

export function Alternator({ currentId, setCurrentId, children })
{
  return (
    <AlternatorContext.Provider value={{ currentId, setCurrentId }}>
      <div styleName="alternator">
        { children }
      </div>
    </AlternatorContext.Provider>
  );
}

export function View({ id=null, justify='right', children })
{
  const { currentId, setCurrentId } = useContext(AlternatorContext);
  const justifyContent = justify === 'right' ? 'flex-end' : justify === 'left' ? 'flex-start' : justify;

  return (
    <Flipper horiz in={currentId === id}>
      <div styleName="view" style={{ justifyContent }}>
        { children }
      </div>
    </Flipper>
  );
}

export function PrimaryView({ justify='right', children })
{
  return <View justify={justify}>{ children }</View>;
}

// -----------------------------------------------------------------------------
//    Chooser
// -----------------------------------------------------------------------------

export function Chooser({ values, labels, select, handler, timeout=15000 })
{
  const prompt = usePrompt();
  const nodeRef = useRef();
  const { currentId, setCurrentId } = useContext(AlternatorContext);
  const [ busy, setBusy ] = useState(null);
  const close = () => setCurrentId(null);
  useClickOutside(nodeRef, close);
  useTimeout(close, timeout);

  const selected = async value =>
  {
    try
    {
      setBusy(value);
      if (handler)
        await handler(value);
      setBusy(null);
      close();
    }
    catch (err)
    {
      setBusy(null);
      prompt.error(err);
    }
  };

  return (
    <div ref={nodeRef} styleName="chooser">
      {
        values.map(value =>
          <button key={value} styleName={busy === value ? 'option-busy' : select === value ? 'option-select' : 'option'}
                  onClick={() => selected(value)}>
            { labels[value] ?? value }
          </button>
        )
      }
    </div>
  );
}

// -----------------------------------------------------------------------------
//    Slider
// -----------------------------------------------------------------------------

export function Slider({ value=0, min=0, max=100, step=1, handler, labelProvider=null, timeout=15000 })
{
  const prompt = usePrompt();
  const [ current, setCurrent ] = useState(value);
  const [ busy, setBusy ] = useState(false);
  const nodeRef = useRef();
  const { currentId, setCurrentId } = useContext(AlternatorContext);
  const close = () => setCurrentId(null);
  useClickOutside(nodeRef, close);
  useTimeout(close, timeout, [ current ]);          // Any time `current` changes, the timer is restarted

  const changed = evt => setCurrent(evt.target.value);
  const save = async () =>
  {
    try
    {
      setBusy(true);
      if (handler)
        await handler(current);
      setBusy(false);
      close();
    }
    catch (err)
    {
      setBusy(false);
      prompt.error(err);
    }
  };

  return (
    <div ref={nodeRef} styleName="slider">
      <input type="range" styleName="slider-input" value={current} min={min} max={max} step={step} onChange={changed} />
      <div styleName="slider-value">{ labelProvider ? labelProvider(current) : current }</div>
      <button styleName={busy ? 'option-busy' : 'option'} onClick={save}>Set</button>
    </div>
  );
}

// -----------------------------------------------------------------------------
//    Menu, MenuItem
// -----------------------------------------------------------------------------

const MenuContext = createContext({ busy: false, setBusy: () => { }, close: () => { } });

export function Menu({ timeout=15000, children })
{
  const nodeRef = useRef();
  const { currentId, setCurrentId } = useContext(AlternatorContext);
  const [ busy, setBusy ] = useState(false);
  const close = () =>
  {
    setBusy(false);
    setCurrentId(null);
  };
  useClickOutside(nodeRef, close);
  useTimeout(close, timeout);

  return (
    <div ref={nodeRef} styleName="chooser">
      <MenuContext.Provider value={{ busy, setBusy, close }}>
        { children }
      </MenuContext.Provider>
    </div>
  );
}

export function MenuItem({ type='primary', children, handler, onClick })
{
  const prompt = usePrompt();
  const { busy, setBusy, close } = useContext(MenuContext);

  const clicked = async evt =>
  {
    if (onClick)
    {
      close();
      onClick(evt);
      return;
    }
    try
    {
      setBusy(true);
      if (handler)
        await handler();
      close();
    }
    catch (err)
    {
      setBusy(false);
      prompt.error(err);
    }
  };

  return (
    <button styleName={busy ? 'option-busy' : `option-${type}`} onClick={clicked}>
      { children }
    </button>
  );
}
