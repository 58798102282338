/** @module chassis */

import React from 'react';
import { createPortal } from 'react-dom';

import styles from './page-header.css';

/**
 * Renders one or more elements in the header bar's page-specifc area.
 *
 * The page-specific  area is the middle portion of the header bar.
 *
 * Use {@link :.CommonHeader} for content common to all pages.
 *
 * @prop {:paper~Element} children - The content of the page-specific header area.
 * @component
 */
export function PageHeader({ align='right', children })
{
  return createPortal(<div styleName={align}>{ children }</div>, document.getElementById('page-header'));
}
