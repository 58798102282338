import { allTypesOf } from '@ocsoft/sync-object';

// -----------------------------------------------------------------------------
//    Registry
// -----------------------------------------------------------------------------

export default class Registry
{
  constructor(components, defaultComponent=null)
  {
    this.components = components;
    this.defaultComponent = defaultComponent;
  }

  supports(device)
  {
    for (let typename of allTypesOf(device))
    {
      let component = this.components[typename];
      if (component)
        return true;
    }
    return false;
  }

  componentFor(device)
  {
    for (let typename of allTypesOf(device))
    {
      let component = this.components[typename];
      if (component)
        return component;
    }
    return this.defaultComponent;
  }
}
