import GraphType from './graph-type.js';

// ----------------------------------------------------------------------------
//    LineGraph
// ----------------------------------------------------------------------------

export default class LineGraph extends GraphType
{
  constructor(state)
  {
    super(state);
  }

  draw(styles, context)
  {
    let state = this.state;
    let plotArea = state.plotArea;
    let primary = state.primary, count = primary.length;

    this.drawVerticalGridLines(styles, context);

    if (count)
    {
      // Build the plot path.
      context.beginPath();
      let first = true;
      let bottom = plotArea.bottom;
      for (let index = 0, x = plotArea.left; index < count; ++ index, x += state.xscale)
      {
        // Little hack to make last datapoint line up with the final vertical gridline.
        if (index === count - 1)
          x += state.xscale;
        let value = primary[(index + state.position) % count];
        if (value !== null)
        {
          let y = bottom - (value - state.axis.minimum) * state.yscale;
          if (first)
          {
            context.moveTo(x, y);
            first = false;
          }
          else
            context.lineTo(x, y);
        }
      }

      // Draw the plot line.
      styles.using(context, 'plotLine', context => context.stroke());

      // Fill below the plot line.
      let lineWidth = styles.value?.plotLine?.lineWidth ?? 1;
      context.save();
      context.translate(0, lineWidth);
      context.lineTo(plotArea.right, plotArea.bottom - lineWidth);
      context.lineTo(plotArea.left, plotArea.bottom - lineWidth);
      context.closePath();
      styles.using(context, 'plotFill', context => context.fill());
      context.restore();

      // Build and draw the secondary plot path
      let secondary = state.secondary;
      if (secondary !== null)
      {
        let ptsPerHour = count / 24, dx = plotArea.width / 24, barWidth = dx / 2;
        for (let index = 0, x = plotArea.left + dx / 4; index < count; x += dx)
        {
          let avg = 0, samples = 0;
          for (let offset = ptsPerHour; offset; -- offset, ++ index)
          {
            let value = secondary[(index + state.position) % count];
            if (value !== null)
            {
              avg += value;
              ++ samples;
            }
          }
          avg /= samples;
          let barHeight = (avg - state.axis.minimum) * state.yscale;
          styles.using(context, 'bar', context => context.fillRect(x, bottom - barHeight, barWidth, barHeight));
        }
      }
    }

    this.drawMidnightGridLine(styles, context);
  }
}
