/** @module paper */

import React from 'react';
import { Transition } from 'react-transition-group';

/**
 * A wrapper component that shows or hides its content using a 3-D flip animation.
 *
 * @prop {boolean} [in=false] - Whether to display the component's children.
 * @prop {boolean} [reverse=false] - Whether to reverse the flip direction.
 * @prop {boolean} [horiz=false] - Whether to perform a horizontal flip instead of a vertical flip.
 * @prop {number} [duration=250] - The duration of the animation in milliseconds.
 * @prop {*} children - The element that is either shown or hidden.
 * @prop {...*} props - Additional properties are forwarded to the `Transition` component.
 * @component
 */
export function Flipper({ in: visible=false, reverse=false, horiz=false, duration=250, children, ...props })
{
  const rx = horiz ? 0 : 1, ry = horiz ? 1 : 0;
  const onEnter = (node, appearing) =>
  {
    node.style.setProperty('transform', `rotate3d(${rx}, ${ry}, 0, ${reverse ? '-180deg' : '180deg'})`);
    node.style.setProperty('opacity', 0);
    node.style.removeProperty('transition');
  };

  const onEntering = (node, appearing) =>
  {
    node && node.scrollTop;                 // Force the transition animation to occur
    node.style.setProperty('transform', `rotate3d(${rx}, ${ry}, 0, 0deg)`);
    node.style.setProperty('opacity', 1);
    node.style.setProperty('transition', `transform ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out`);
  };

  const onExit = node =>
  {
    node.style.setProperty('transform', `rotate3d(${rx}, ${ry}, 0, 0deg)`);
    node.style.setProperty('opacity', 1);
    node.style.removeProperty('transition');
  };

  const onExiting = node =>
  {
    node && node.scrollTop;                 // Force the transition animation to occur
    node.style.setProperty('transform', `rotate3d(${rx}, ${ry}, 0, ${reverse ? '180deg' : '-180deg'})`);
    node.style.setProperty('opacity', 0);
    node.style.setProperty('transition', `transform ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out`);
  };

  return (
    <Transition in={visible} timeout={duration}
                onEnter={onEnter} onEntering={onEntering} onExit={onExit} onExiting={onExiting}
                mountOnEnter unmountOnExit { ...props }>
      { children }
    </Transition>
  );
}
