import Registry from '../registry.js';
import UnknownDevice from './unknown.js';
import Light from './light.js';
import Appliance from './appliance.js';
import Thermostat from './thermostat.js';
import WeatherStation from './wx-station.js';
import WindowCovering from './window-covering.js';
import TemperatureSensor from './temperature-sensor.js';
import CurrentSensor from './current-sensor.js';
import AlarmZone from './alarm-zone.js';
import AlarmPartition from './alarm-partition.js';
import Tag from './tag.js';
import GarageDoor from './garage-door.js';

// -----------------------------------------------------------------------------
//    WidgetRegistry
// -----------------------------------------------------------------------------

class WidgetRegistry extends Registry
{
  constructor()
  {
    super(
    {
      'light':                        Light,
      'appliance':                    Appliance,
      'thermostat':                   Thermostat,
      'alarm-zone':                   AlarmZone,
      'alarm-partition':              AlarmPartition,
      'tag':                          Tag,
      'temperature-sensor':           TemperatureSensor,
      'current-sensor':               CurrentSensor,
      'wx-station':                   WeatherStation,
      'window-covering':              WindowCovering,
      'garage-door':                  GarageDoor
    }, UnknownDevice);
  }
}

export default new WidgetRegistry();
