// ----------------------------------------------------------------------------
//    GraphType
// ----------------------------------------------------------------------------

export default class GraphType
{
  constructor(state)
  {
    this.state = state;
  }

  draw(styles, context)
  {
  }

  get plot()
  {
    return this.state.plot;
  }

  get meta()
  {
    return this.state.meta;
  }

  getViewX(index)
  {
    let state = this.state;
    if (state.datasets.length === 0)
      return -1;
    index -= state.position;
    if (index < 0)
      index += state.datasets[0].length;
    return state.xscale * index + state.plotArea.left;
  }

  adjustMargins(plotArea)
  {
  }

  drawVerticalGridLines(styles, context)
  {
    const state = this.state, plotArea = state.plotArea;
    const y0 = plotArea.top, y1 = plotArea.bottom, dx = plotArea.width / 24;
    let x = plotArea.left;
    if (state.meta.gridLineProvider !== undefined)
    {
      if (typeof state.meta.gridLineProvider === 'function')
      {
        let indices = state.meta.gridLineProvider(this.plot);
        for (let entry of indices)
        {
          let style = 'minorGridLine', index;
          if (typeof entry === 'object')
          {
            index = entry.index;
            if (entry.type === 'dotted')
              style = 'midnightGridLine';
            else if (entry.type === 'major')
              style = 'majorGridLine';
          }
          else
            index = entry;
          let x = this.getViewX(index);
          styles.using(context, style, context =>
          {
            context.beginPath();
            context.moveTo(x, y0);
            context.lineTo(x, y1);
            context.stroke();
          });
        }
      }
      return
    }

    // The default assumes hourly grid lines.
    styles.using(context, 'minorGridLine', context =>
    {
      for (let index = 0; index < 25; ++ index, x += dx)
      {
        context.beginPath();
        context.moveTo(x, y0);
        context.lineTo(x, y1);
        context.stroke();
      }
    });
  }

  drawMidnightGridLine(styles, context)
  {
    const state = this.state, plotArea = state.plotArea;
    if (state.meta.gridLineProvider !== undefined)
      return;
    const y0 = plotArea.top, y1 = plotArea.bottom;
    let x = plotArea.left + (state.primary.length - state.position) * state.xscale;
    context.beginPath();
    context.moveTo(x, y0);
    context.lineTo(x, y1);
    styles.using(context, 'midnightGridLine', context => context.stroke());
  }
}
