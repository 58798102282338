import { sprintf } from '@ocsoft/sprintf';
import Styles from './styles.js';
import GraphState from './state.js';

const TOUCH_UNUSED = 0;
const TOUCH_IDLE = 1;
const TOUCH_INIT = 2;
const TOUCH_TRACK = 3;

const activeEngines = new Set();

// ----------------------------------------------------------------------------
//    Engine
// ----------------------------------------------------------------------------

// Important properties to keep updated after construction:
//    data:         The data object, which must consist of an array of values for each named property.
//    position:     The position within the plot
//    resolution:   The number of milliseconds represented by each datapoint in the plot.

export default class Engine
{
  constructor(canvas, overlayCanvas)
  {
    this.canvas = canvas;
    this.overlayCanvas = overlayCanvas;

    this.meta = null;
    this.plot = null;

    this.width = 0;
    this.height = 0;

    let ratio = window.devicePixelRatio || 1;
    let moveTrack = (clientX, clientY) =>
    {
      let rect = this.overlayCanvas.getBoundingClientRect();
      let x = (clientX - rect.left) / (rect.right - rect.left) * this.overlayCanvas.width / ratio;
      let y = (clientY - rect.top) / (rect.bottom - rect.top) * this.overlayCanvas.height / ratio;
      let index = this._state !== null ? this._state.getPlotIndex(x) : -1;
      this.shareTrack(index, x, y);
    };

    let touchState = TOUCH_UNUSED, xStart, yStart, xRecent, yRecent, scrollTimer = null;
    this.overlayCanvas.addEventListener('mousemove', evt =>
    {
      if (touchState == TOUCH_UNUSED)
        moveTrack(evt.clientX, evt.clientY);
    });
    this.overlayCanvas.addEventListener('mouseleave', () => this.shareTrack(-1, -1, -1));

    let cancelActive = () =>
    {
      if (touchState == TOUCH_TRACK)
        this.shareTrack(-1, -1, -1);
      if (scrollTimer != null)
      {
        clearTimeout(scrollTimer);
        scrollTimer = null;
      }
      touchState = TOUCH_IDLE;
    }

    this.overlayCanvas.addEventListener('touchstart', evt =>
    {
      if (touchState != TOUCH_UNUSED && touchState != TOUCH_IDLE)
      {
        cancelActive();
        return;
      }
      if (evt.touches.length > 1)
        return;
      touchState = TOUCH_INIT;
      scrollTimer = setTimeout(() =>
      {
        scrollTimer = null;
        moveTrack(xRecent, yRecent);
        touchState = TOUCH_TRACK;
      }, 100);
      let touch = evt.touches[0];
      xStart = xRecent = touch.clientX;
      yStart = yRecent = touch.clientY;
    });

    this.overlayCanvas.addEventListener('touchmove', evt =>
    {
      if (touchState == TOUCH_UNUSED || touchState == TOUCH_IDLE)
        return;
      let touch = evt.touches[0];
      xRecent = touch.clientX;
      yRecent = touch.clientY;
      if (touchState == TOUCH_INIT)
      {
        // Lock in scrolling if we've moved sufficiently away from where we started vertically.
        if (Math.abs(yRecent - yStart) >= 10)
        {
          cancelActive();
          return;
        }
        // Lock in tracking if we've moved sufficiently away from where we started horizontally.
        if (Math.abs(xRecent - xStart) >= 10)
        {
          clearTimeout(scrollTimer);
          scrollTimer = null;
          touchState = TOUCH_TRACK;
        }
      }
      if (touchState == TOUCH_TRACK)
      {
        evt.preventDefault();
        moveTrack(touch.clientX, touch.clientY);
      }
    });
    this.overlayCanvas.addEventListener('touchend', evt => cancelActive());
    this.overlayCanvas.addEventListener('touchcancel', evt => cancelActive());

    this._context = this.canvas.getContext('2d');
    this._overlayContext = this.overlayCanvas.getContext('2d');

    this._styles = Styles.of(null);
    this._state = null;

    activeEngines.add(this);
  }

  get styles()
  {
    return this._styles;
  }

  set styles(value)
  {
    this._styles = Styles.of(value);
  }

  get trackingGroup()
  {
    return this._state?.trackingGroup ?? null;
  }

  dispose()
  {
    activeEngines.delete(this);
  }

  draw(resize=false)
  {
    // Detect retina displays and blow up the canvas accordingly. Without doing this, we get blurry text
    // inside the graph.
    if (resize || this.width == 0 || this.height == 0)
    {
      let width = this.canvas.clientWidth, height = this.canvas.clientHeight;
      this.width = width;
      this.height = height;
      let ratio = window.devicePixelRatio || 1;
      width *= ratio;
      height *= ratio;
      this.canvas.width = width;
      this.canvas.height = height;
      this.overlayCanvas.width = width;
      this.overlayCanvas.height = height;
      if (ratio != 1)
      {
        this._context.scale(ratio, ratio);
        this._overlayContext.scale(ratio, ratio);
      }
    }

    this._context.lineWidth = 1;
    this._context.lineJoin = 'round';

    this._context.clearRect(0, 0, this.width, this.height);
    if (this.meta == null || this.plot == null || this.width == 0 || this.height == 0)
    {
      this._state = null;
      return;
    }

    let state = this._state = new GraphState(this.meta, this.plot, this.width, this.height);
    let axis = state.axis, rightAxis = state.rightAxis;

    let maxLabelWidth = this._styles.using(this._context, 'axisLabel', context => axis.calcMaxLabelWidth(context) + 10);
    let maxRightLabelWidth = ! rightAxis ? 4 :
      this._styles.using(this._context, 'rightAxisLabel', context => rightAxis.calcMaxLabelWidth(context) + 10);
    state.reserveLabelArea(maxLabelWidth, maxRightLabelWidth);

    // Draw the horizontal grid lines and Y axis labels.
    let dy = -axis.step * state.yscale;
    let y = state.plotArea.top + state.plotArea.height - (axis.firstItemValue - axis.minimum) * state.yscale;
    let x0 = state.plotArea.left, x1 = state.plotArea.right;
    for (let index = 0; index < axis.items.length; ++ index, y += dy)
    {
      let item = axis.items[index], lineStyle;;
      if (item.label)
      {
        this._styles.using(this._context, 'axisLabel', (context, style) =>
        {
          context.fillText(item.label, x0 - 8, y + (style?.labelYOffset ?? 0));
        });
        lineStyle = 'majorGridLine';
      }
      else
        lineStyle = 'minorGridLine';
      this._styles.using(this._context, lineStyle, context =>
      {
        context.beginPath();
        context.moveTo(x0, y);
        context.lineTo(x1, y);
        context.stroke();
      });
    }

    if (rightAxis)
    {
      let dy = -rightAxis.step * state.yscaleRight;
      let y = state.plotArea.top + state.plotArea.height - (rightAxis.firstItemValue - rightAxis.minimum) * state.yscaleRight;
      let x0 = state.plotArea.right;
      for (let index = 0; index < rightAxis.items.length; ++ index, y += dy)
      {
        let item = rightAxis.items[index], lineStyle;;
        if (item.label)
        {
          this._styles.using(this._context, 'rightAxisLabel', (context, style) =>
          {
            context.fillText(item.label, x0 + 8, y + (style?.labelYOffset ?? 0));
          });
        }
      }
    }

    state.type.draw(this._styles, this._context);
  }

  track({ index, x, y, floatLabel=true })
  {
    let state = this._state;
    if (index === undefined)
      index = state !== null ? state.getPlotIndex(x) : -1;
    this._overlayContext.clearRect(0, 0, this.width, this.height);
    if (index < 0)
      return;
    if (! state.meta.detailFormatter && ! state.axisLabelTracking)
      return;
    x = state.getViewX(index);
    this._overlayContext.beginPath();
    this._overlayContext.moveTo(x, state.plotArea.top);
    this._overlayContext.lineTo(x, state.plotArea.bottom);
    this._styles.using(this._overlayContext, 'sweeper', context => context.stroke());

    let values = state.datasets.map(data => data[index]), value = values[0];
    let rightValues = state.rightDatasets.map(data => data[index]);
    if (value == null)
      return;

    if (state.axisLabelTracking)
    {
      let label = state.meta.labelFormatter ? state.meta.labelFormatter(value) : "" + value;
      let y = state.plotArea.top + state.plotArea.height - (value - state.axis.minimum) * state.yscale;

      let textHeight = this._styles.value?.overlayAxisText?.height ?? 16;
      let yPadding = this._styles.value?.overlayAxisText?.yPadding ?? 0;
      let boxHeight = textHeight + 2 * yPadding;
      this._styles.using(this._overlayContext, 'overlayAxisBox', context =>
      {
        context.fillRect(0, y - boxHeight / 2, state.plotArea.left - 2, boxHeight);
      });
      this._styles.using(this._overlayContext, 'overlayAxisText', (context, style) =>
      {
        context.fillText(label, state.plotArea.left - 8, y + (style?.labelYOffset ?? 0));
      });

      if (state.rightAxis)
      {
        let value = rightValues[0];
        let label = state.meta.right.labelFormatter ? state.meta.right.labelFormatter(value) : "" + value;
        let y = state.plotArea.top + state.plotArea.height - (value - state.rightAxis.minimum) * state.yscaleRight;

        let textHeight = this._styles.value?.overlayAxisText?.height ?? 16;
        let yPadding = this._styles.value?.overlayAxisText?.yPadding ?? 0;
        let boxHeight = textHeight + 2 * yPadding;
        this._styles.using(this._overlayContext, 'overlayRightAxisBox', context =>
        {
          context.fillRect(state.plotArea.right + 2, y - boxHeight / 2, this.width, boxHeight);
        });
        this._styles.using(this._overlayContext, 'overlayRightAxisText', (context, style) =>
        {
          context.fillText(label, state.plotArea.right + 8, y + (style?.labelYOffset ?? 0));
        });
      }
    }

    if (floatLabel && state.meta.detailFormatter)
    {
      let minutes = Math.floor((index * state.resolution) / 1000 / 60);
      let timeStr = sprintf("%02d:%02d", Math.floor(minutes / 60), minutes % 60);
      let lines = state.meta.detailFormatter(timeStr, ...values, ...rightValues, ...state.extras.map(data => data[index]));
      if (! Array.isArray(lines))
        lines = [ lines ];
      let textWidth=0, textHeight=0, xPadding, yPadding, lineHeight;
      this._styles.using(this._overlayContext, 'overlayText', (context, style) =>
      {
        lineHeight = style?.height ?? 14;
        for (let line of lines)
        {
          let metrics = context.measureText(line);
          if (metrics.width > textWidth)
            textWidth = metrics.width;
          textHeight += lineHeight;
        }
        xPadding = style?.xPadding ?? 0;
        yPadding = style?.yPadding ?? 0;
      });
      let boxHeight = textHeight + yPadding* 2;
      let boxWidth = textWidth + xPadding * 2;

      let dbx = x - boxWidth / 2;
      if (dbx < 0)
        dbx = 0;
      else if (dbx > this.width - boxWidth)
        dbx = this.width - boxWidth;
      let dby = y - boxHeight - 24;
      if (dby < 0)
        dby = y + 24;
      this._styles.using(this._overlayContext, 'overlayBox', context =>
      {
        context.fillRect(dbx, dby, boxWidth, boxHeight);
      });
      this._styles.using(this._overlayContext, 'overlayText', context =>
      {
        let yLine = dby + yPadding;
        for (let line of lines)
        {
          context.fillText(line, dbx + xPadding, yLine);
          yLine += lineHeight;
        }
      });
    }
  }

  shareTrack(index, x, y)
  {
    if (this.trackingGroup !== null)
    {
      for (let engine of activeEngines)
      {
        if (engine.trackingGroup == this.trackingGroup)
          engine.track({ index, y, floatLabel: engine === this });
      }
    }
    else
      this.track({ index, y, floatLabel: true });
  }

  clientXToPlotIndex(clientX)
  {
    let ratio = window.devicePixelRatio || 1;
    let rect = this.overlayCanvas.getBoundingClientRect();
    let x = (clientX - rect.left) / (rect.right - rect.left) * this.overlayCanvas.width / ratio;
    return this._state?.getPlotIndex(x) ?? -1;
  }
}
