import React from 'react';
import { Timestamp, Duration } from '@ocsoft/time';
import { commit, execute } from '@ocsoft/sync-object';
import { Icon, IconButton, usePrompt } from '@ocsoft/paper';
import { TagWindow } from '@ocsoft/chassis';
import { Frame } from '../frame.js';

import { faTag as tagIcon, faClock as clockIcon, faCircleNotch as editIcon } from '@fortawesome/pro-duotone-svg-icons';
import { faUserTag as fallbackIcon } from '@fortawesome/pro-solid-svg-icons';

import styles from './tag.css';

const statusLabels =
{
  absent:             tag => tag.lastExitTime ? `Absent since ${Timestamp.of(tag.lastExitTime).shortString}` : "Absent",
  present:            tag => tag.lastEntryTime ? `Present since ${Timestamp.of(tag.lastEntryTime).shortString}` : "Present",
  waitingForExit:     <b>Waiting for exit</b>,
  recentlyExited:     <b>Recently exited</b>,
  waitingForReturn:   <b>Waiting for return</b>
};

// ---------------------------------------------------------------------------
//    Tag (Widget)
// ---------------------------------------------------------------------------

export default function Tag({ device })
{
  const prompt = usePrompt();

  const statusInfo = statusLabels[device.status] ?? device.status;
  const statusLabel = typeof statusInfo === 'function' ? statusInfo(device) : statusInfo;
  const edit = () => prompt.modal(<TagWindow tag={device} />, { closeOnBackdropClick: true });
  const armed = device.status !== 'present' && device.status !== 'absent';

  return (
    <Frame device={device} icon={device.icon || fallbackIcon}>
      <div styleName={device.avail ? 'root' : 'unavail'}>
        <div styleName="main">
          <div styleName="status">
            <Icon icon={tagIcon} styleName="line-icon" />
            { statusLabel }
          </div>
          <div styleName="trip">
            <Icon icon={clockIcon} styleName="line-icon" />
            <TripInfo tag={device} />
          </div>
        </div>
        <IconButton icon={editIcon} styleName={armed ? 'edit-armed' : "edit-icon"} disabled={! device.avail} onClick={edit} />
      </div>
    </Frame>
  );
}

function TripInfo({ tag })
{
  switch (tag.status)
  {
    case 'present':
    case 'absent':
    case 'waitingForExit':
    default:
      if (tag.lastTripDuration === null)
        return "No previous trip";
      return `Last trip took ${Duration.of(tag.lastTripDuration).summary}`;

    case 'recentlyExited':
    case 'waitingForReturn':
      return `Gone since ${Timestamp.of(tag.lastExitTime).shortString}`;
  }
}
