import React from 'react';
import { Graph } from '@ocsoft/paper';
import { Frame } from '../frame.js';
import { useDevicePlot } from '../device-util.js';
import { temperatureMeta } from '../graph-meta.js';

import { faTachometerAlt as widgetIcon } from '@fortawesome/pro-duotone-svg-icons';

import styles from './graph.css';

// ---------------------------------------------------------------------------
//    Thermostat (Widget)
// ---------------------------------------------------------------------------

export default function Thermostat({ device })
{
  const [ plot ] = useDevicePlot(device);

  return (
    <Frame device={device} icon={widgetIcon} type='graph'>
      <Graph meta={temperatureMeta} plot={plot} styleName='graph' />
    </Frame>
  );
}
