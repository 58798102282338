import React, { useState } from 'react';
import { useEventTarget } from '@ocsoft/paper';

// ---------------------------------------------------------------------------
//    Favorites
// ---------------------------------------------------------------------------

class Favorites extends EventTarget
{
  #selected;

  constructor()
  {
    super();

    let str = window.localStorage.favoriteDevices;
    if (str)
    {
      try
      {
        this.#selected = new Set(JSON.parse(str));
      }
      catch (err)
      {
      }
    }
    if (! this.#selected)
      this.#selected = new Set();
  }

  get selected()
  {
    return this.#selected;
  }

  toggle(uuid)
  {
    let replace = new Set(this.#selected);
    if (! replace.delete(uuid))
      replace.add(uuid);
    this.#selected = replace;
    this.#save();
    this.dispatchEvent(new Event('update'));
  }

  #save()
  {
    window.localStorage.favoriteDevices = JSON.stringify(Array.from(this.#selected));
  }
}

export const favorites = new Favorites();

// ---------------------------------------------------------------------------
//    useFavorites
// ---------------------------------------------------------------------------

export function useFavorites()
{
  const [ selected, setSelected ] = useState(favorites.selected);
  useEventTarget(favorites, 'update', evt => setSelected(favorites.selected));

  const toggle = uuid => favorites.toggle(uuid);

  return [ selected, toggle ];
}
