import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { commit, execute } from '@ocsoft/sync-object';
import { BusyIcon, usePrompt } from '@ocsoft/paper';
import { Frame } from '../frame.js';
import { getCap } from '../device-util.js';

import { faLightbulb as offIcon, faLightbulbOn as onIcon } from '@fortawesome/pro-duotone-svg-icons';
import { faPowerOff as powerIcon } from '@fortawesome/pro-solid-svg-icons';

import styles from './light.css';

// ---------------------------------------------------------------------------
//    Light (Widget)
// ---------------------------------------------------------------------------

export default function Light({ device })
{
  const prompt = usePrompt();
  const dimmable = getCap(device, 'dimmable');
  const level = dimmable ? device.level : device.level ? 100 : 0;

  const toggle = () => execute(device, 'toggle');
  const adjust = evt =>
  {
    if (! dimmable)
      prompt.maybeError(toggle());
    else
    {
      let dimmer = evt.currentTarget;
      let level = Math.floor((evt.clientX - dimmer.offsetLeft) / dimmer.clientWidth * 100);
      if (level < 25)
        level = 0;
      else if (level > 90)
        level = 100;
      prompt.maybeError(commit(device, { level }));
    }
  };

  return (
    <Frame device={device} icon={device.level ? onIcon : offIcon}>
      <div styleName="root">
        <div styleName="dimmer" onClick={adjust}>
          <div styleName="bar" style={{ width: `${level}%` }}></div>
          <div styleName="level">{ level ? (dimmable ? `${level}%` : 'On') : 'Off' }</div>
        </div>
        <BusyIcon icon={powerIcon} styleName={ level ? 'power-on' : 'power' } handler={toggle} />
      </div>
    </Frame>
  );
}
