import React from 'react';
import { ValueRoot, FormattedValue } from '@ocsoft/paper';
import { Frame } from '../frame.js';

import widgetIcon from '../res/elec.svg';

import styles from './current-sensor.css';

// ---------------------------------------------------------------------------
//    CurrentSensor (Widget)
// ---------------------------------------------------------------------------

export default function CurrentSensor({ device })
{
  return (
    <Frame device={device} icon={widgetIcon}>
      <ValueRoot obj={device}>
        <div styleName="root">
          <div styleName="left">
            <div styleName="watt"><FormattedValue name="watt" format="%.2fW" /></div>
            <div styleName="voltage"><FormattedValue name="voltage" convert={voltage => voltage / 1000} format="%.2fV" /></div>
            <div styleName="current"><FormattedValue name="current" format="%.2fA" /></div>
          </div>
        </div>
      </ValueRoot>
    </Frame>
  );
}
