import React, { useState, useMemo } from 'react';
import { commit, execute } from '@ocsoft/sync-object';
import { Frame } from '../frame.js';
import { Alternator, View, PrimaryView, Slider } from '../alternator.js';
import { Button } from '../button.js';

import closedIcon from '../res/door8.svg';
import openIcon from '../res/door2.svg';

import styles from './garage-door.css';

const percentLabelProvider = value => `${value}%`;

// ---------------------------------------------------------------------------
//    GarageDoor (Widget)
// ---------------------------------------------------------------------------

export default function GarageDoor({ device }) {
  const [ currentId, setCurrentId ] = useState(null);
  const moving = device.status.state !== 'idle';

  return (
    <Frame device={device} icon={device.status.position ? openIcon : closedIcon}>
      <div styleName="root">
        <div styleName="left">
          <div styleName="position">{ formatPosition(device.status) }</div>
        </div>
        <Alternator currentId={currentId} setCurrentId={setCurrentId}>
          <PrimaryView>
            <div styleName="right">
              <Button styleName="action" handler={() => execute(device, 'open')} disabled={moving}>Open</Button>
              <Button styleName="action" handler={() => execute(device, 'close')} disabled={moving}>Close</Button>
              <Button styleName="action" onClick={() => setCurrentId('percent')} disabled={moving}>%</Button>
            </div>
          </PrimaryView>
          <View id="percent">
            <Slider value={device.status.position} min={0} max={100} labelProvider={percentLabelProvider}
                    handler={position => commit(device, { position })} />
          </View>
        </Alternator>
      </div>
    </Frame>
  );
}

function formatPosition({ state, position }) {
  const posText = position !== null ? ` (${position}%)` : '';
  switch (state) {
    case 'closing':
      return `Closing${posText}...`;

    case 'opening':
      return `Opening${posText}...`;
  }
  if (position === null) {
    return '-';
  }
  switch (position) {
    case 0:
      return "Closed";

    case 100:
      return "Fully Open";

    default:
      return `Open${posText}`;
  }
}
