import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ValueRoot, FormattedValue } from '@ocsoft/paper';
import { Frame } from '../frame.js';

import { faSunCloud as widgetIcon } from '@fortawesome/pro-duotone-svg-icons';
import { faChevronDoubleDown as rapidFallIcon, faChevronDown as fallIcon, faLongArrowAltRight as steadyIcon,
         faChevronUp as riseIcon, faChevronDoubleUp as rapidRiseIcon } from '@fortawesome/pro-solid-svg-icons';

import styles from './wx-station.css';

const barometerTrends =
{
  '-2':     rapidFallIcon,
  '-1':     fallIcon,
  '0':      steadyIcon,
  '1':      riseIcon,
  '2':      rapidRiseIcon
};

// ---------------------------------------------------------------------------
//    WeatherStation (Widget)
// ---------------------------------------------------------------------------

export default function WeatherStation({ device })
{
  return (
    <Frame device={device} icon={widgetIcon}>
      <ValueRoot obj={device}>
        <div styleName="root">
          <div styleName="temp-column">
            <div styleName="temperature"><FormattedValue name="temperature" format="%.1f&deg;" /></div>
            <div styleName="apparent"><FormattedValue name="apparent" format="Feels %.1f&deg;" /></div>
            <div styleName="dewpoint"><FormattedValue name="dewpoint" format="Dew %.1f&deg;" /></div>
          </div>
          <div styleName="wind-column">
            <div styleName="compass">
              <div styleName="text-overlay"><FormattedValue name="windSpeed" /></div>
              <div styleName="arrow" style={{ transform: `rotate(${device.windDirection ?? 0}deg)` }} />
            </div>
            <div styleName="wind-gust"><FormattedValue name="windGust" format="Gust: %s mph" zeroFormat="Calm" /></div>
          </div>
          <div styleName="misc-column">
            <div styleName="section">
              <div styleName="section-head">Barometer</div>
              <div styleName="baro">
                <FormattedValue name="barometer" format="%.2f in." />
                <FontAwesomeIcon icon={barometerTrends[device.barometerTrend ?? 0]} styleName="barometer-trend" />
              </div>
            </div>
            <div styleName="section">
              <div styleName="section-head">Rain</div>
              <div styleName="rain-rate"><FormattedValue name="rainRate" format="%.2f in./hr." zeroFormat="No Rain" /></div>
            </div>
          </div>
        </div>
      </ValueRoot>
    </Frame>
  );
}
