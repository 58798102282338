import { urlOf } from '@ocsoft/sync-object';
import { useSyncSingleton } from '@ocsoft/sync-react';

export function getCap(device, name)
{
  return device.meta && device.meta.capabilities ? device.meta.capabilities[name] : undefined;
}

export function deviceTitle(device)
{
  let name = device.location !== undefined ? device.location : '';
  if (device.name)
  {
    if (name)
      name += ' ';
    name += device.name;
  }
  return name !== '' ? name : "Untitled Device";
}

export function useDevicePlot(device)
{
  return useSyncSingleton(device ? urlOf(device) + '/plot' : null);
}
