/** @module paper */

import React, { useState } from 'react';
import { Button } from './button.js';
import { IconButton } from './icon-button.js';
import { usePrompt } from './prompt.js';

// -----------------------------------------------------------------------------
//    BusyButton
// -----------------------------------------------------------------------------

/**
 * A button component wrapper that adds promise logic and error handling.
 *
 * The specified handler, which may be asynchronous, is executed when the button is pressed. While the handler is
 * in progress, the button is rendered with its `busy` property set to `true`, typically disabling the button and
 * showing the wait cursor. If the handler results in an error, an {@link :~Prompt#error} prompt is displayed.
 *
 * This component has no visual representation of its own. It simply wraps any actual button component to add
 * additional functionality.
 *
 * @prop {:~Component} [component] - The button component to use. Defaults to {@link :.Button}.
 * @prop {function} [handler=null] - A function to execute when the button is pressed.
 * @prop {number} [minDuration=200] - The minimum number of milliseconds that the button will remain busy. This delay
 *   helps debounce requests.
 * @prop {string} [width] - An optional explicit width (e.g. `'100px'`) to assign to the button's styles.
 * @prop {...*} [props] - Any additional properties are passed to the button component. This includes any children of
 *   this component.
 * @component
 */
export function BusyButton({ component: ButtonComponent=Button, handler=null, minDuration=200, children, width, ...props })
{
  const [ busy, setBusy ] = useState(false);
  const prompt = usePrompt();

  const clicked = async () =>
  {
    if (handler)
    {
      try
      {
        setBusy(true);
        if (minDuration)
          await Promise.all([ Promise.resolve(handler()), new Promise(resolve => setTimeout(resolve, minDuration)) ]);
        else
          await handler();
      }
      catch (err)
      {
        prompt.error(err);
      }
      setBusy(false);
    }
  };

  return (
    <ButtonComponent { ...props } busy={busy} style={{ width }} onClick={clicked}>
      { children }
    </ButtonComponent>
  );
}

// -----------------------------------------------------------------------------
//    BusyIcon
// -----------------------------------------------------------------------------

export function BusyIcon({ handler = null, children, ...props })
{
  return (
    <BusyButton { ...props } component={IconButton} handler={handler}>
      { children }
    </BusyButton>
  );
}
