/** @module paper */

import React, { useRef, useEffect } from 'react';
import { useTheme } from '../paper.js';
import { useResizeObserver } from '../hooks.js';
import Engine from './engine.js';

import styles from './graph.css';

// -----------------------------------------------------------------------------
//    Graph
// -----------------------------------------------------------------------------

/**
 * A line or bar graph.
 *
 * @prop {:.Graph~Plot} [plot=null] - The plot data. If `null`, nothing is drawn.
 * @prop {:.Graph~Meta} meta - Required metadata.
 * @prop {string} [themeKey='graph'] - The {@link :~Theme} key that contains the graph's colors and geometry settings.
 * @prop {string} [className] - An optional CSS class name to assign to the element. The default configures a 320x240 pixel
 *   element.
 * @prop {string} [width] - An optional `width` style (e.g. `'400px'`) assigned to the element.
 * @prop {string} [height] - An optional `height` style (e.g. `'400px'`) assigned to the element.
 * @prop {:.Graph~NavCallback} [onNav] - An optional navigation callback. If specified, the graph becomes clickable, and
 *   the callback is invoked whenever a sample is clicked.
 * @component
 */
export function Graph({ className=styles.default, width, height, meta, themeKey='graph', plot=null, onNav })
{
  const containerRef = useRef();
  const mainRef = useRef();
  const overlayRef = useRef();
  const engineRef = useRef();
  const theme = useTheme();

  useEffect(() =>
  {
    const engine = engineRef.current = new Engine(mainRef.current, overlayRef.current);
    return () => engine.dispose();
  }, [ ]);

  useEffect(() =>
  {
    const engine = engineRef.current;
    engine.meta = meta;
    engine.styles = theme.get(themeKey, null);
    engine.plot = plot;
    engine.draw();
  }, [ meta, themeKey, plot ]);

  useResizeObserver(containerRef.current, () => engineRef.current.draw(true));

  const handleNav = evt =>
  {
    let index = engineRef.current?.clientXToPlotIndex(evt.clientX) ?? -1;
    if (index >= 0)
      onNav(index);
  };

  return (
    <div ref={containerRef} className={className} style={{ width, height, position: 'relative' }}>
      <canvas ref={mainRef} styleName='main' />
      <canvas ref={overlayRef} styleName={onNav ? 'clickable-overlay' : 'overlay'} onClick={onNav ? handleNav : undefined} />
    </div>
  );
}

/**
 * The current state of a {@link :.Graph}.
 *
 * The size of each array in `data` is determined by the `resolution` and the overall duration of the plot. For example, a
 * 24-hour plot with a resolution of 300,000 milliseconds (5 minutes) requires 288 samples: 24 hours multiplied by 12
 * samples per hour.
 *
 * The `position` property is used to specify the index of the first data point to plot on the x-axis. When a non-zero
 * position is used, the array is treated as a ring buffer; once the end of the array is reached, processing wraps around to
 * the beginning in order to fill the entire graph. This mechanism exists to support a useful characteristic of 24-hour
 * plots: the index of each sample can be used to determine the time that the sample was taken; the sample at index 0 is
 * always the midnight sample. When displaying a graph of the last 24 hours, the `position` property allows the midnight
 * sample to float within the graph so that the leftmost sample drawn is from 24 hours prior to the current time.
 *
 * @prop {Object<string, number[]>} data - A map of property names to data sets.
 * @prop {number} position - The index within the data array that represents the current time.
 * @prop {number} resolution - The interval between two adjacent samples in the data array (in milliseconds).
 * @typedef {Object} :.Graph~Plot
 */

/**
 * Graph metadata.
 *
 * @prop {:.Graph~Type} type - The graph type.
 * @prop {number} minimum - The minimum y-axis value.
 * @prop {number} maximum - The maximum y-axis value.
 * @prop {boolean} [canNarrowRange=false] - Whether the y-axis range can be narrowed to fit the actual data points used in
 *   the graph.
 * @prop {:~NumberFormatter} [labelFormatter] - The axis label formatter. If omitted, the value itself is used as its label.
 * @prop {:.Graph~DetailFormatter} [detailFormatter] - The detail (tracking bubble) formatter. If omitted, no tracking bubble
 *   is displayed.
 * @prop {string | string[]} property - The name of each data property to plot in the graph. Note that some graph types limit
 *   the number of properties to 1 or 2, ignoring any additional properties..
 * @prop {string | string[]} [extra] - The names of optional additional data properties. While these properties will not be
 *   plotted, a detail formatter may choose to include their values in the tracking bubble.
 * @prop {?*} [trackingGroup=null] - All graphs with identical tracking group values (other than `null`) are synchronized
 *   when the user moves the pointer over any of the graphs.
 * @prop {boolean} [axisLabelTracking=true] - Whether the current value is highlighted in the axis label area during
 *   tracking.
 * @prop {:.Graph~IndicatorProvider} [indicator] - An optional indicator provider for bar graphs.
 * @prop {:.Graph~BarColorProvider} [barColorProvider] - An optional bar color provider for bar graphs.
 * @prop {:.Graph~GridLineProvider} [gridLineProvider] - An optional callback function invoked to determine the indices
 *   and types of vertical grid lines. If this property is omitted, vertical grid lines are drawn at the start of each
 *   hour (assuming a 24-hour dataset). If this property is set to any value other than a function, no vertical grid
 *   lines are drawn at all.
 * @prop {:.Graph~AxisMeta} [right] - Information about the second vertical axis used by the `'multiline'` graph type.
 * @typedef {Object} :.Graph~Meta
 */

/**
 * Graph axis metadata.
 *
 * @prop {number} minimum - The minimum y-axis value.
 * @prop {number} maximum - The maximum y-axis value.
 * @prop {boolean} [canNarrowRange=false] - Whether the y-axis range can be narrowed to fit the actual data points used in
 *   the graph.
 * @prop {:~NumberFormatter} [labelFormatter] - The axis label formatter. If omitted, the value itself is used as its label.
 * @prop {string | string[]} property - The name of each data property to plot in the graph. Note that some graph types limit
 *   the number of properties to 1 or 2, ignoring any additional properties..
 * @prop {boolean} [axisLabelTracking=true] - Whether the current value is highlighted in the axis label area during
 *   tracking.
 * @typedef {Object} :.Graph~AxisMeta
 */

/**
 * Supported graph types.
 *
 * @enum :.Graph~Type
 * @case line - A line graph. Supports up to two properties, the first of which is the primary line graph, and the
 *   second of which appears as an hourly bar graph below the line graph.
 * @case multiline - A multiline graph. Supports left and right axes and multiple properties, each of which is drawn
 *   with a different line color and optional fill.
 * @case bar - A bar graph. Supports multiple properties, each of which is drawn with a different bar color.
 */

/**
 * A callback function that provides the tracking bubble text for a particular sample position in the graph.
 *
 * @param {string} timeStr - The sample's time as a string.
 * @param {...number} values - The values of each property at the position.
 * @param {...number} extras - The values of each `extra` property at the position.
 * @returns {string | string[]} A single line of text, or an array of text lines. Only plain text is allowed; any HTML
 *   tags or entities will be rendered as-is.
 * @callback :.Graph~DetailFormatter
 */

/**
 * A callback function that provides the indicator to display below the bar at the specified position in a bar graph.
 *
 * @param {Object<string, number[]>} data - The plot data provided to the `Graph`.
 * @param {number} pos - The current position within the plot.
 * @returns {?:.Graph~Indicator} The indicator to display at that position, or `null` for no indicator.
 * @callback :.Graph~IndicatorProvider
 */

/**
 * Information about an indicator to display below a bar in a bar graph.
 *
 * @prop {string} [shape='triangle'] - The indicator shape: `'triangle'` or `'circle'`.
 * @prop {string} [style='minor-indicator'] - The indicator style: `'major-indicator'`, `'minor-indicator'`, or
 *   `'red-indicator'`.
 * @typedef {Object} :.Graph~Indicator
 */

/**
 * A callback function that provides the fill color for a bar in a bar graph.
 *
 * @param {number} value - The sample value represented by the bar.
 * @param {string} prop - The property name.
 * @returns {string} The fill color (e.g. `'lime'` or `'#00f'`).
 * @callback :.Graph~BarColorProvider
 */

/**
 * A callback function that determines the type and index of the vertical grid lines.
 *
 * @param {:.Graph~Plot} plot - The current plot.
 * @returns {Array<number | :.Graph~GridLineInfo>} The indices of the samples in the plot that should receive vertical
 *   grid lines.
 * @callback :.Graph~GridLineProvider
 */

/**
 * Information about a single vertical grid line.
 *
 * @prop {number} index - The index of the sample within the plot's dataset.
 * @prop {string} [type='minor'] - The type of grid line to draw: `'dotted'`, `'major'`, or `'minor'`.
 * @typedef {Object} :.Graph~GridLineInfo
 */

/**
 * A callback function invoked when the user clicks or taps a sample in the plot.
 *
 * @param {number} index - The index of the plot sample that was clicked.
 * @callback :.Graph~NavCallback
 */
