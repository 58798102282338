import { sprintf } from '@ocsoft/sprintf';

export const temperatureMeta =
{
  property:         [ 'temperature', 'dewpoint' ],
  title:            "Temperature/Dewpoint",
  minimum:          0,
  maximum:          100,
  canNarrowRange:   true,
  labelFormatter:   value => value !== null ? value.toFixed(1) : '',
  detailFormatter:  (timeStr, temperature, dewpoint) =>
  {
    return (
    [
      temperature != null ? sprintf("%.1f\u00b0 at %s", temperature, timeStr) : sprintf("--- at %s", timeStr),
      dewpoint != null ? sprintf("Dewpoint: %.1f\u00b0", dewpoint) : "Dewpoint: ---"
    ]);
  },
  trackingGroup:    'wx'
}

export const currentMeta =
{
    property:         'voltage',
    minimum:          0,
    maximum:          26000,
    canNarrowRange:   true,
    labelFormatter:   value => value !== null ? (value / 1000).toFixed(2) : '',
    detailFormatter:  (timeStr, voltage, watt) =>
    {
      return (
      [
        voltage != null ? sprintf("%.2fV at %s", voltage / 1000, timeStr) : sprintf("--- at %s", timeStr),
        watt != null ? sprintf("%.2fW", watt) : "---W"
      ]);
    },
    right: {
      minimum:  0,
      maximum:  40,
      canNarrowRange: true,
      labelFormatter: value => value !== null ? value.toFixed(1) : '',
      property:       'watt'
    },
    type:             'multiline',
    trackingGroup:    'wx'
}
