import { sprintf } from '@ocsoft/sprintf';
import Rectangle from './rect.js';
import Axis from './axis.js';
import LineGraph from './line-graph.js';
import BarGraph from './bar-graph.js';
import MultilineGraph from './multiline-graph.js';
import GraphType from './graph-type.js';

// ----------------------------------------------------------------------------
//    GraphState
// ----------------------------------------------------------------------------

export default class GraphState
{
  constructor(meta, plot, width, height)
  {
    this.meta = meta;
    this.plot = plot;
    this.type = this.makeGraphType(meta.type);
    this.trackingGroup = meta.trackingGroup ?? null;
    this.axisLabelTracking = meta.axisLabelTracking ?? true;

    let props = Array.isArray(meta.property) ? meta.property : [ meta.property ];
    this.props = props;
    this.datasets = props.map(key => plot.data[key]);
    let extraProps = Array.isArray(meta.extra) ? meta.extra : meta.extra != null ? [ meta.extra ] : [ ];
    this.extras = extraProps.map(key => plot.data[key]);
    this.position = plot.position ?? 0;
    this.resolution = plot.resolution ?? 0;

    this.axis = new Axis(meta.minimum, meta.maximum, meta.canNarrowRange, this.datasets,
                         height >= 300 ? 10 : 5, meta.labelFormatter);
    if (meta.right)
    {
      let { minimum, maximum, canNarrowRange, labelFormatter, property } = meta.right;
      let props = Array.isArray(property) ? property : [ property ];
      this.rightDatasets = props.map(key => plot.data[key]);
      this.rightAxis = new Axis(minimum, maximum, canNarrowRange, this.rightDatasets, height >= 300 ? 10 : 5, labelFormatter);
    }
    else
    {
      this.rightAxis = null;
      this.rightDatasets = [ ];
    }

    this.plotArea = new Rectangle(0, 0, width, height);
    this.xscale = null;
    this.yscale = null;
    this.yscaleRight = null;
  }

  get sampleCount()
  {
    return this.datasets[0].length;
  }

  get primary()
  {
    return this.datasets[0] ?? null;
  }

  get secondary()
  {
    return this.datasets[1] ?? null;
  }

  reserveLabelArea(width, rightWidth=4)
  {
    this.plotArea.margin(width, 10, rightWidth, 10);
    this.type.adjustMargins(this.plotArea);
    this.xscale = this.plotArea.width / this.datasets[0].length;
    this.yscale = this.plotArea.height / (this.axis.maximum - this.axis.minimum);
    this.yscaleRight = ! this.rightAxis ? null : this.plotArea.height / (this.rightAxis.maximum - this.rightAxis.minimum);
  }

  getPlotIndex(x)
  {
    if (this.datasets.length === 0)
      return -1;
    x -= this.plotArea.left;
    if (x < 0 || x >= this.plotArea.width)
      return -1;
    let index = Math.floor(x / this.xscale);
    return (index + this.position) % this.datasets[0].length;
  }

  getViewX(index)
  {
    return this.type.getViewX(index);
  }

  makeGraphType(typeName='line')
  {
    switch (typeName)
    {
      case 'line':
        return new LineGraph(this);

      case 'bar':
        return new BarGraph(this);

      case 'multiline':
        return new MultilineGraph(this);

      default:
        return new GraphType(this);
    }
  }
}
