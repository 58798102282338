import React, { useState, useMemo } from 'react';
import { commit, execute } from '@ocsoft/sync-object';
import { Frame } from '../frame.js';
import { Alternator, View, PrimaryView, Slider } from '../alternator.js';
import { Button } from '../button.js';

import { faBlinds as closedIcon, faBlindsRaised as openIcon } from '@fortawesome/pro-duotone-svg-icons';

import styles from './window-covering.css';

const percentLabelProvider = value => `${value}%`;

// ---------------------------------------------------------------------------
//    WindowCovering (Widget)
// ---------------------------------------------------------------------------

export default function WindowCovering({ device })
{
  const [ currentId, setCurrentId ] = useState(null);
  const battery = device.batteryLevel !== undefined ? `Battery ${device.batteryLevel}%` : "";
  const moving = device.targetLevel !== null;

  return (
    <Frame device={device} icon={device.level ? openIcon : closedIcon}>
      <div styleName="root">
        <div styleName="left">
          <div styleName="position">{ formatPosition(device.level, device.targetLevel) }</div>
          <div styleName="battery">{ battery }</div>
        </div>
        <Alternator currentId={currentId} setCurrentId={setCurrentId}>
          <PrimaryView>
            <div styleName="right">
              <Button styleName="action" handler={() => execute(device, 'open')} disabled={moving}>Open</Button>
              <Button styleName="action" handler={() => execute(device, 'close')} disabled={moving}>Close</Button>
              <Button styleName="action" onClick={() => setCurrentId('percent')} disabled={moving}>%</Button>
            </div>
          </PrimaryView>
          <View id="percent">
            <Slider value={device.level} min={0} max={100} labelProvider={percentLabelProvider}
                    handler={level => commit(device, { level })} />
          </View>
        </Alternator>
      </div>
    </Frame>
  );
}

function formatPosition(level, targetLevel)
{
  if (targetLevel !== null)
  {
    switch (targetLevel)
    {
      case 0:
        return "Closing...";

      case 100:
        return "Opening...";

      default:
        return "Adjusting...";
     }
   }
  switch (level)
  {
    case 0:
      return "Closed";

    case 100:
      return "Fully Open";

    default:
      return `Open (${level}%)`;
  }
}
