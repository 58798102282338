import React from 'react';
import { Timestamp } from '@ocsoft/time';
import { useSyncList, useSorter } from '@ocsoft/sync-react';
import { Icon } from '@ocsoft/paper';

import { faExclamationCircle as icon } from '@fortawesome/pro-regular-svg-icons';

import styles from './faults.css';

// -----------------------------------------------------------------------------
//    FaultBox
// -----------------------------------------------------------------------------

export function FaultBox()
{
  const [ faults ] = useSyncList(`/api/faults`);
  const sorter = useSorter('-+created');

  if (faults.length === 0)
    return null;

  return (
    <div styleName="box">
    {
      faults.sort(sorter).map(fault =>
        <Fault key={fault.uuid} obj={fault} />)
    }
    </div>
  );
}

// -----------------------------------------------------------------------------
//    Fault
// -----------------------------------------------------------------------------

export function Fault({ obj })
{
  return (
    <div styleName="fault">
      <Icon icon={icon} styleName='icon' />
      <div styleName="info">
        <div styleName="message">{ obj.message }</div>
        <div styleName="source">{ obj.server?.location } { obj.server?.name }</div>
        <div styleName="ts">{ Timestamp.of(obj.created).conciseString }</div>
      </div>
    </div>
  );
}
