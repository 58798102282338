import React, { useMemo } from 'react';
import { useSyncList, useSorter } from '@ocsoft/sync-react';
import { useSearch, useLocalStorage } from '@ocsoft/paper';
import { PageHeader, PageContent, HeaderToggleIcon } from '@ocsoft/chassis';
import { useFavorites } from './favorites.js';
import widgetRegistry from './widgets/index.js';
import graphRegistry from './graph-widgets/index.js';
import { FaultBox } from './faults.js';

import { faChartBar as graphIcon } from '@fortawesome/pro-solid-svg-icons';

import styles from './widget-page.css';

// -----------------------------------------------------------------------------
//    WidgetPage
// -----------------------------------------------------------------------------

export default function WidgetPage({ mode })
{
  const [ graphMode, setGraphMode ] = useLocalStorage('graph-mode', false);
  const registry = graphMode ? graphRegistry : widgetRegistry;
  const filter = useWidgetFilter(mode, registry);
  const [ devices ] = useSyncList('/api/devices', filter, [ filter ]);
  const sorter = useSorter([ 'location', 'name' ]);

  devices.sort(sorter);

  return (
    <>
      <PageHeader>
        <HeaderToggleIcon icon={graphIcon} select={graphMode} onToggle={() => setGraphMode(! graphMode)} />
      </PageHeader>
      <PageContent>
        <FaultBox />
        <div styleName="root">
          {
            devices.map(device =>
            {
              const Component = registry.componentFor(device);
              return Component ? <Component key={device.uuid} device={device} /> : null;
            })
          }
        </div>
      </PageContent>
    </>
  );
}

function useWidgetFilter(mode, registry)
{
  const found = useSearch();
  const [ favorites, toggle ] = useFavorites();

  return useMemo(() =>
  {
    return device =>
    {
      if (! registry.supports(device))
        return false;
      if (! found(device.location) && ! found(device.name))
        return false;
      switch (mode)
      {
        case 'active':
          return device.attentionLevel !== 'low';

        case 'favorites':
          return favorites.has(device.uuid);

        default:
          return true;
      }
    };
  }, [ mode, registry, found, favorites ]);
}
