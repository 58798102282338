// ----------------------------------------------------------------------------
//    Axis
// ----------------------------------------------------------------------------

export default class Axis
{
  constructor(minimum, maximum, canNarrowRange, plots, labelCount, labelFormatter)
  {
    if (canNarrowRange && plots.length)
    {
      minimum = Infinity;
      maximum = -Infinity;
    }

    // Extend the range if necessary to accomodate all plots.
    for (let plot of plots)
    {
      for (let index = 0; index < plot.length; ++ index)
      {
        const value = plot[index];
        if (value !== null)
        {
          if (value < minimum)
            minimum = value;
          if (value > maximum)
            maximum = value;
        }
      }
    }

    this.minimum = minimum;
    this.maximum = maximum;

    // Determine an appropriate display range and scale.
    let interval = (maximum - minimum) / (labelCount - 1);
    if (interval != 0)
    {
      let negative = false;
      if (interval < 0)
      {
        negative = true;
        interval = -interval;
      }
      const magnitude = Math.pow(10, Math.floor(Math.log(interval) / Math.LN10));
      let fraction = interval / magnitude;
      if (fraction < 1.5)
        fraction = 1;
      else if (fraction < 3)
        fraction = 2;
      else if (fraction < 7)
        fraction = 5;
      else
        fraction = 10;

      if (negative)
        fraction = -fraction;
      interval = fraction * magnitude;
    }

    this.interval = interval;
    this.step = interval / 2;

    // Calculate the value for each label and minor grid line.
    const items = [ ],
          start = Math.floor(minimum / interval) * interval,
          end = Math.ceil(maximum / interval) * interval;
    let first = NaN;
    for (let major = start; major <= end; major += interval)
    {
      if (major >= minimum && major <= maximum)
      {
        if (isNaN(first))
          first = major;
        const label = labelFormatter ? labelFormatter(major) : "" + major;
        items.push({ value: major, label: label });
      }
      const minor = major + interval / 2;
      if (minor >= minimum && minor <= maximum)
      {
        if (isNaN(first))
          first = minor;
        items.push({ value: minor });
      }
    }
    this.items = items;
    this.firstItemValue = first;
  }

  calcMaxLabelWidth(context)
  {
    let result = 0;
    for (let item of this.items)
    {
      if (item.label)
      {
        let metrics = context.measureText(item.label);
        if (metrics.width > result)
          result = metrics.width;
      }
    }
    return result;
  }
}
