import React, { useState } from 'react';
import { usePrompt } from '@ocsoft/paper';

import styles from './button.css';

// -----------------------------------------------------------------------------
//    Button
// -----------------------------------------------------------------------------

export function Button({ className, width, onClick, handler, children, ...props })
{
  const prompt = usePrompt();
  const [ busy, setBusy ] = useState(false);
  const baseStyle = styles[busy ? 'busy-button' : 'button'];

  const clicked = async evt =>
  {
    if (onClick)
      onClick(evt);
    else if (handler)
    {
      try
      {
        setBusy(true);
        await handler();
        setBusy(false);
      }
      catch (err)
      {
        setBusy(false);
        prompt.error(err);
      }
    }
  };

  return (
    <button className={className ? `${className} ${baseStyle}` : baseStyle} onClick={clicked} style={{ width }} { ...props }>
      { children }
    </button>
  );
}
