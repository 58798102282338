import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { execute } from '@ocsoft/sync-object';
import { Frame } from '../frame.js';
import { Button } from '../button.js';

import { faSensor as readyIcon, faSensorAlert as notReadyIcon } from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as okIcon, faInfoCircle as infoIcon,
         faExclamationCircle as alertIcon } from '@fortawesome/pro-regular-svg-icons';

import styles from './alarm-zone.css';

const statusTable =
[
  { key: 'ready',         icon: okIcon,       css: 'ok',        label: "Zone Ready"           },
  { key: 'tripped',       icon: infoIcon,     css: 'warning',   label: "Zone Tripped"         },
  { key: 'alarm',         icon: alertIcon,    css: 'danger',    label: "Zone Alarm"           },
  { key: 'bypassed',      icon: infoIcon,     css: 'info',      label: "Bypassed"             },
  { key: 'tamper',        icon: alertIcon,    css: 'danger',    label: "Tamper Detected"      },
  { key: 'trouble',       icon: infoIcon,     css: 'warning',   label: "Trouble Detected"     },
  { key: 'lowBattery',    icon: infoIcon,     css: 'warning',   label: "Low Battery"          }
];

// ---------------------------------------------------------------------------
//    AlarmZone (Widget)
// ---------------------------------------------------------------------------

export default function AlarmZone({ device })
{
  return (
    <Frame device={device} icon={device.status.ready ? readyIcon : notReadyIcon}>
      <div styleName="root">
        <div styleName="left">
          {
            statusTable.filter(entry => device.status[entry.key] === true)
              .map(entry => <StatusLine key={entry.key} entry={entry} />)
          }
        </div>
        <div styleName="right">
          <Button styleName="bypass" handler={() => execute(device, 'toggle-bypass')}>
            { device.status.bypassed ? "Clear Bypass" : "Bypass" }
          </Button>
        </div>
      </div>
    </Frame>
  );
}

function StatusLine({ entry })
{
  return (
    <div styleName="status-line">
      <FontAwesomeIcon icon={entry.icon} className={styles[entry.css]} />
      <span styleName="status-label">{ entry.label }</span>
    </div>
  );
}
