import React from 'react';
import { Graph } from '@ocsoft/paper';
import { Frame } from '../frame.js';
import { useDevicePlot } from '../device-util.js';
import { currentMeta } from '../graph-meta.js';

import widgetIcon from '../res/elec.svg';

import styles from './graph.css';

// ---------------------------------------------------------------------------
//    CurrentSensor (Widget)
// ---------------------------------------------------------------------------

export default function CurrentSensor({ device })
{
  const [ plot ] = useDevicePlot(device);

  return (
    <Frame device={device} icon={widgetIcon} type='graph'>
      <Graph meta={currentMeta} plot={plot} styleName="graph" />
    </Frame>
  );
}
