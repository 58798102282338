import React, { useState, useMemo } from 'react';
import { sprintf } from '@ocsoft/sprintf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { commit } from '@ocsoft/sync-object';
import { ValueRoot, FormattedValue, usePrompt } from '@ocsoft/paper';
import { Alternator, View, PrimaryView, Chooser, Slider } from '../alternator.js';
import { Frame } from '../frame.js';
import { Button } from '../button.js';
import { getCap } from '../device-util.js';

import { faTachometerAlt as widgetIcon } from '@fortawesome/pro-duotone-svg-icons';

import styles from './thermostat.css';

const modeLabels =
{
  off:              'Off',
  heat:             'Heat',
  cool:             'Cool',
  auto:             'Auto',
  auxHeat:          'Aux Heat',
  resume:           'Resume',
  fanOnly:          'Fan Only',
  furnace:          'Furnace',
  dryAir:           'Dry Air',
  moistAir:         'Moist Air',
  autoChangeover:   'Auto C/O',
  heatEcon:         'Heat Econ',
  coolEcon:         'Cool Econ',
  away:             'Away'
};

const fanModeLabels =
{
  auto:             'Fan Auto',
  on:               'Fan On',
  autoHigh:         'Auto High',
  onHigh:           'On High',
  circulate:        'Circulate'
};

const stateLabels =
{
  idle:             'Idle',
  heating:          'Heating',
  cooling:          'Cooling',
  pendingHeat:      'Pending Heat',
  pendingCool:      'Pending Cool',
  vent:             'Venting'
};

const fanStateLabels =
{
  running:          'Fan',
  runningHigh:      'Fan High'
};

const setpointLabelProvider = value => sprintf("%.0f\u00b0", value);

// ---------------------------------------------------------------------------
//    Thermostat (Widget)
// ---------------------------------------------------------------------------

export default function Thermostat({ device })
{
  const prompt = usePrompt();
  const [ currentId, setCurrentId ] = useState(null);
  const stateText = useStateText(device);
  const mode = device.thermostatMode, fanMode = device.fanMode;
  const heatCap = getCap(device, 'heatSetpoint'), coolCap = getCap(device, 'coolSetpoint');

  return (
    <Frame device={device} icon={widgetIcon}>
      <ValueRoot obj={device}>
        <div styleName="root">
          <div styleName="left">
            <div styleName="temperature"><FormattedValue name="temperature" format="%.1f&deg;" /></div>
            <div styleName="dewpoint"><FormattedValue name="dewpoint" format="Dew %.1f&deg;" /></div>
            <div styleName="humidity"><FormattedValue name="humidity" format="%s%% RH" /></div>
            <div styleName="state">{ stateText }</div>
          </div>
          <Alternator currentId={currentId} setCurrentId={setCurrentId}>
            <PrimaryView>
              <div styleName="right-column">
                <Button styleName="mode" onClick={() => setCurrentId('mode')}>{ modeLabels[mode] ?? mode }</Button>
                <Button styleName="mode" onClick={() => setCurrentId('fanMode')}>
                  { fanModeLabels[fanMode] ?? fanMode }
                </Button>
              </div>
              <div styleName="right-column">
                <Button styleName="heat-setpoint" onClick={() => setCurrentId('heatSetpoint')}>
                  <FormattedValue name="heatSetpoint" format="%.0f&deg;" />
                </Button>
                <Button styleName="cool-setpoint" onClick={() => setCurrentId('coolSetpoint')}>
                  <FormattedValue name="coolSetpoint" format="%.0f&deg;" />
                </Button>
              </div>
            </PrimaryView>
            <View id="mode">
              <Chooser values={getCap(device, 'thermostatMode')} labels={modeLabels} select={mode}
                       handler={value => commit(device, { thermostatMode: value })} />
            </View>
            <View id="fanMode">
              <Chooser values={getCap(device, 'fanMode')} labels={fanModeLabels} select={fanMode}
                       handler={value => commit(device, { fanMode: value })} />
            </View>
            <View id="heatSetpoint">
              <Slider value={device.heatSetpoint} min={heatCap?.min ?? 50} max={heatCap?.max ?? 80} step={heatCap?.step}
                      labelProvider={setpointLabelProvider} handler={value => commit(device, { heatSetpoint: value })} />
            </View>
            <View id="coolSetpoint">
              <Slider value={device.coolSetpoint} min={coolCap?.min ?? 50} max={coolCap?.max ?? 80} step={coolCap?.step}
                      labelProvider={setpointLabelProvider} handler={value => commit(device, { coolSetpoint: value })} />
            </View>
          </Alternator>
        </div>
      </ValueRoot>
    </Frame>
  );
}

function useStateText(device)
{
  return useMemo(() =>
  {
    let state = [ ], label = stateLabels[device.thermostatState];
    if (label)
      state.push(label);
    label = fanStateLabels[device.fanState];
    if (label)
      state.push(label);
    return state.join(", ");
  }, [ device ]);
}
