// ----------------------------------------------------------------------------
//    Rectangle
// ----------------------------------------------------------------------------

export default class Rectangle
{
  constructor(left=0, top=0, right=0, bottom=0)
  {
    this.left = left;
    this.top = top;
    this.right = right;
    this.bottom = bottom;
  }

  get width()
  {
    return this.right - this.left;
  }

  get height()
  {
    return this.bottom - this.top;
  }

  set(left, top, right, bottom)
  {
    this.left = left;
    this.top = top;
    this.right = right;
    this.bottom = bottom;
  }

  copyFrom(other)
  {
    this.left = other.left;
    this.top = other.top;
    this.right = other.right;
    this.bottom = other.bottom;
  }

  margin(left, top, right, bottom)
  {
    this.left += left;
    this.top += top;
    this.right -= right;
    this.bottom -=bottom;
  }
}
