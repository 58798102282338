/** @module paper */

import React from 'react';
import { useInitFocus } from './hooks.js';
import { usePromptWindow } from './prompt.js';

import styles from './menu.css';

// -----------------------------------------------------------------------------
//    Menu
// -----------------------------------------------------------------------------

/**
 * A menu, generally meant to be rendered in a {@link :~Prompt#popover}.
 *
 * @prop {number} [columns=1] - The number of columns used to display the menu items.
 * @prop {string} [minWidth] - An optional `min-width` style to apply to the menu.
 * @prop {string} [width] - An optional `width` style to apply to the menu.
 * @prop {:~Element} children - The menu items, typically {@link :.MenuItem} or {@link :.MenuInput}.
 * @component
 */
export function Menu({ columns=1, minWidth, width, children })
{
  const nodeRef = useInitFocus();

  return (
    <div ref={nodeRef} styleName='menu' style={{ gridTemplateColumns: `repeat(${columns}, 1fr)`, minWidth, width }}>
      { children }
    </div>
  );
}

// -----------------------------------------------------------------------------
//    MenuItem
// -----------------------------------------------------------------------------

/**
 * A clickable menu item.
 *
 * @prop {function} [onClick] - A callback invoked when the item is clicked.
 * @prop {boolean} [disabled=false] - Whether the item is disabled.
 * @prop {:~Element} children - The content of the menu item.
 * @component
 */
export function MenuItem({ onClick, disabled=false, children })
{
  const window = usePromptWindow();

  const clicked = () =>
  {
    if (window)
      window.close();
    if (onClick)
      onClick();
  };

  return (
    <button styleName='item' disabled={disabled} onClick={clicked}>
      { children }
    </button>
  );
}

// -----------------------------------------------------------------------------
//    MenuInput
// -----------------------------------------------------------------------------

/**
 * An `<input>` element within a menu.
 *
 * @prop {EventHandler} [onEnter] - A callback function invoked when Enter is pressed.
 * @prop {EventHandler} [onKeyDown] - A callback function invoked when a key is pressed.
 * @prop {:~InputChangeCallback} [onValue] - A callback function invoked when the input text changes.
 * @prop {EventHandler} [onChange] - A callback function invoked when then input text changes.
 * @prop {...*} props - Any additional properties are forwarded to the underlying input element.
 * @component
 */
export function MenuInput({ onEnter, onKeyDown, onValue, onChange, ...props })
{
  const window = usePromptWindow();

  const handleKey = evt =>
  {
    if (evt.key === 'Enter' && onEnter)
    {
      evt.stopPropagation();
      if (window)
        window.close();
      onEnter();
      return;
    }
    if (onKeyDown)
      onKeyDown(evt);
  };

  const changed = evt =>
  {
    if (onValue)
      onValue(evt.target.value);
    if (onChange)
      onChange(evt);
  }

  return (
    <input { ...props } styleName="input" onChange={changed} onKeyDown={handleKey} />
  );
}
