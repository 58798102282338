import React, { useRef, useState } from 'react';
import { Icon } from '@ocsoft/paper';
import { deviceTitle } from './device-util.js';
import { useFavorites } from './favorites.js';

import { faStar as favoriteIcon } from '@fortawesome/pro-solid-svg-icons';
import { faStar as notFavoriteIcon } from '@fortawesome/pro-regular-svg-icons';

import styles from './frame.css';

// -----------------------------------------------------------------------------
//    Frame
// -----------------------------------------------------------------------------

export function Frame({ device, icon, children, type='normal' })
{
  const favRef = useRef();
  const [ favorites, toggle ] = useFavorites();
  const [ pending, setPending ] = useState(null);
  const favorite = pending ?? favorites.has(device.uuid);

  const toggleFavorite = () =>
  {
    if (pending !== null)
      return;
    setPending(! favorite);
    const node = favRef.current;
    node.onanimationend = () =>
    {
      node.onanimationend = null;
      setPending(null);
      toggle(device.uuid);
    };
  };

  return (
    <div styleName="root">
      <div styleName={type}>
        <div styleName="top">
          <Icon icon={icon} styleName="type-icon" />
          <div styleName="title">{ deviceTitle(device) }</div>
          <button type="button" styleName={iconClass(favorite, pending)} onClick={toggleFavorite} ref={favRef}>
            <Icon icon={favorite ? favoriteIcon : notFavoriteIcon} />
          </button>
        </div>
        <div styleName="widget">
          { children }
        </div>
      </div>
    </div>

  );
}

function iconClass(favorite, pending)
{
  if (pending !== null)
    return pending ? 'fav-active-changed' : 'fav-changed';
  return favorite ? 'fav-active' : 'fav-icon';
}
