import React from 'react';
import { PageContent, Section, Sections, EmptySection, ChangePassword } from '@ocsoft/chassis';

import { faKey as sectionIcon } from '@fortawesome/pro-duotone-svg-icons';

// -----------------------------------------------------------------------------
//    PasswordPage
// -----------------------------------------------------------------------------

export default function PasswordPage()
{
  return (
    <PageContent>
      <Sections>
        <Section icon={sectionIcon} title="Change My Password">
          <ChangePassword url="/api/admin/password" />
        </Section>
      </Sections>
    </PageContent>
  );
}
