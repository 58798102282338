/** @module chassis */

import React from 'react';
import { navigate } from '@reach/router';
import { MenuItem } from '@ocsoft/paper';

/**
 * Renders a {@link :paper.MenuItem} that navigates to the specified application page when clicked.
 *
 * @prop {string} path - The path of the page to which to navigate.
 * @prop {:paper~Element} children - The content of the menu item.
 * @component
 */
export function MenuLinkItem({ path, children })
{
  const clicked = () => navigate(path);

  return (
    <MenuItem onClick={clicked}>
      { children }
    </MenuItem>
  );
}
