/** @module chassis */

import React, { useState } from 'react';
import agent from '@ocsoft/agent';
import { Button, ButtonGroup, Form, SubmitButton, Alert, FormErrorAlert, TextField } from '@ocsoft/paper';

// -----------------------------------------------------------------------------
//    ChangePassword
// -----------------------------------------------------------------------------

/**
 * A password change form.
 *
 * @prop {string} [url='/api/password'] - The URL to which the new password is committed.
 * @component
 */
export function ChangePassword({ url='/api/password' })
{
  const [ data, setData ] = useState({ username: '', password: '', newPassword: '', newPassword2: '' });
  const [ success, setSuccess ] = useState(false);

  const validate = (data, error) =>
  {
    if (data.newPassword.length < 6)
      error('newPassword', "The new password must contain at least 6 characters");
    if (data.newPassword2 !== data.newPassword)
      error('newPassword2', "The passwords do not match");
  };

  const submit = data =>
  {
    let { newPassword2, ...props } = data;
    return agent.post(url, props);
  };

  const done = () =>
  {
    setSuccess(true);
  };

  const changed = data =>
  {
    setData(data);
    setSuccess(false);
  };

  return (
    <Form data={data} onChange={changed} submit={submit} onSuccess={done} validate={validate} gapAbove={16}>
      { success && <Alert type="success" caption="Your password has been successfully changed." /> }
      <FormErrorAlert />
      <TextField name="username" label="Username" minLength={1} maxLength={80}
                 placeholder="Enter your username" autoCapitalize="none" />
      <TextField name="password" label="Current password" placeholder="Enter your existing password" type="password"
                 maxLength={128} />
      <TextField name="newPassword" label="New password" placeholder="Enter at least 6 characters" type="password"
                 minLength={6} maxLength={128} />
      <TextField name="newPassword2" label="Re-enter the new password" placeholder="Enter at least 6 characters"
                 type="password" minLength={6} maxLength={128} />
      <ButtonGroup justify='right'>
        <SubmitButton label="Accept New Password" />
      </ButtonGroup>
    </Form>
  );
}
