import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { commit, execute } from '@ocsoft/sync-object';
import { BusyIcon, usePrompt } from '@ocsoft/paper';
import { Frame } from '../frame.js';
import { getCap } from '../device-util.js';

import { faOutlet as widgetIcon, faPowerOff as powerIcon } from '@fortawesome/pro-solid-svg-icons';

import styles from './appliance.css';

// ---------------------------------------------------------------------------
//    Appliance (Widget)
// ---------------------------------------------------------------------------

export default function Appliance({ device })
{
  const prompt = usePrompt();

  const toggle = () => execute(device, 'toggle');

  return (
    <Frame device={device} icon={widgetIcon}>
      <div styleName="root">
        <div styleName="dimmer" onClick={toggle}>
          <div styleName="bar" style={{ width: `${device.power ? 100 : 0}%` }}></div>
          <div styleName="level">{ device.power ? 'On' : 'Off' }</div>
        </div>
        <BusyIcon icon={powerIcon} styleName={ device.power ? 'power-on' : 'power' } handler={toggle} />
      </div>
    </Frame>
  );
}
