import React from 'react';
import { ValueRoot, FormattedValue } from '@ocsoft/paper';
import { Frame } from '../frame.js';

import { faThermometerHalf as widgetIcon } from '@fortawesome/pro-duotone-svg-icons';

import styles from './temperature-sensor.css';

// ---------------------------------------------------------------------------
//    TemperatureSensor (Widget)
// ---------------------------------------------------------------------------

export default function TemperatureSensor({ device })
{
  return (
    <Frame device={device} icon={widgetIcon}>
      <ValueRoot obj={device}>
        <div styleName="root">
          <div styleName="left">
            <div styleName="temperature"><FormattedValue name="temperature" format="%.1f&deg;" /></div>
            <div styleName="dewpoint"><FormattedValue name="dewpoint" format="Dew %.1f&deg;" /></div>
            <div styleName="humidity"><FormattedValue name="humidity" format="%s%% RH" /></div>
          </div>
          <div styleName="right">
            <div styleName="battery"><FormattedValue name="batteryLevel" format="Battery %s%%" /></div>
          </div>
        </div>
      </ValueRoot>
    </Frame>
  );
}
