/** @module paper */

import React, { useContext, useMemo } from 'react';
import { sprintf } from '@ocsoft/sprintf';
import { ListMeta, pick } from '@ocsoft/form-util';

const RootContext = React.createContext({ obj: null, wrap: text => text });

// -----------------------------------------------------------------------------
//    ValueRoot
// -----------------------------------------------------------------------------

/**
 * Associates a data object with a tree containing one or more value components that render data from the object.
 *
 * @example
 * <ValueRoot obj={currentWeather}>
 *   <FormattedValue name="temperature" format="%.1f\u00b0" />
 * </ValueRoot>
 *
 * @prop {Object} obj - The object from which values are retrieved.
 * @prop {Object} [styles=null] - An optional map of style keys to CSS class names. This map is used when the `classify`
 *   option is set on a value within this root.
 * @prop {string} [defaultStyle=null] - The default style key (or CSS class name if `styles` is `null`) to use when rendering
 *   values within this root. If `null`, values with no class name specified are not wrapped at all.
 * @prop {:~ValueRenderFunction} [render] - An optional function invoked to render values within this root. The default
 *   render function wraps a stringified value in a `span` element with the calculated CSS class name.
 * @prop {:~Element} [children] - The element tree influenced by this value root.
 * @component
 */
export function ValueRoot({ obj, styles=null, defaultStyle=null, render, children })
{
  const wrap = useMemo(() =>
  {
    if (render)
      return render;
    if (typeof styles === 'object' && styles !== null)
    {
      return (text, value, classify, extra, className) =>
      {
        if (! className)
        {
          const key = classify ? (typeof classify === 'function' ? classify(value) : classify) : defaultStyle;
          className = key != null ? styles[key] : null;
        }
        return className ? <span className={className}>{ text }</span> : text;
      };
    }
    if (defaultStyle)
      return (text, value, classify, extra, className) => <span className={className ?? defaultStyle}>{ text }</span>;
    return (text, value, classify, extra, className) =>
    {
      if (className)
        return <span className={className}>{ text }</span>;
      return text;
    };
  }, [ styles, defaultStyle, render ]);

  return (
    <RootContext.Provider value={{ obj, wrap }}>
      { children }
    </RootContext.Provider>
  );
}

// -----------------------------------------------------------------------------
//    useValueContainer()
// -----------------------------------------------------------------------------

/**
 * Gets the data object from the nearest {@link :.ValueRoot} ancestor.
 *
 * @returns {?Object} The data object, or `null` if the calling component has no {@link :.ValueRoot} ancestor.
 * @hook
 */
export function useValueContainer()
{
  const { obj } = useContext(RootContext);
  return obj;
}

// -----------------------------------------------------------------------------
//    FormattedValue
// -----------------------------------------------------------------------------

/**
 * A formatted numeric value.
 *
 * @prop {*} [value] - The value to be formatted. Either this property or `name` must be provided.
 * @prop {string} [name] - The property name or path (e.g. `'foo.bar'`) to the value within the current {@link :.ValueRoot}.
 * @prop {string|:.FormattedValue~Stringifier} [format='%s'] - The `printf`-style format or a function that returns the string
 *   equivalent for a given value.
 * @prop {string} [nullFormat='---'] - The string to display if the value is null.
 * @prop {string} [zeroFormat] - An optional string to display if the value is zero.
 * @prop {:.FormattedValue~Converter} [convert] - An optional conversion function to run on the value before formatting the string.
 * @prop {string | :~ValueClassifier} [classify] - The style key associated with the value, or a function that returns the same.
 * @prop {string} [className] - The CSS class name to apply to the value. This property takes precedence over `classify`
 *   if present.
 * @prop {*} [extra] - Optional extra data to provide to the value's renderer.
 * @component
 */
export function FormattedValue({ value, name, format='%s', nullFormat='---', zeroFormat, convert, classify, className, extra })
{
  const { obj, wrap } = useContext(RootContext);
  if (name)
    value = pick(obj, name);

  let text;
  if (value == null)
    text = nullFormat;
  else
  {
    if (convert)
      value = convert(value);
    if (value === 0 && zeroFormat != null)
      text = zeroFormat;
    else
    {
      try
      {
        if (typeof format === 'function')
          text = format(value);
        else
          text = sprintf(format, value);
      }
      catch (err)
      {
        text = `[${err.message}]`;
      }
    }
  }

  return wrap(text, value, classify, extra, className);
}

// -----------------------------------------------------------------------------
//    EnumValue
// -----------------------------------------------------------------------------

/**
 * An enumerated value.
 *
 * @prop {*} [value] - The value to be formatted. Either this property or `name` must be provided.
 * @prop {string} [name] - The property name or path (e.g. `'foo.bar'`) to the value within the current {@link :.ValueRoot}.
 * @prop {:form-util.ListMeta | Object} meta - Metadata about the members of the enumeration. This may be a ListMeta
 *   instance or an anonymous object containing properties to pass to ListMeta's constructor.
 * @prop {string} [nullFormat='---'] - The string to display if the value is null.
 * @prop {string | :~ValueClassifier} [classify] - The style key associated with the value, or a function that returns the same.
 * @prop {string} [className] - The CSS class name to apply to the value. This property takes precedence over `classify`
 *   if present.
 * @prop {*} [extra] - Optional extra data to provide to the value's renderer.
 * @component
 */
export function EnumValue({ value, name, meta, nullFormat='---', classify, className, extra })
{
  const { obj, wrap } = useContext(RootContext);
  const listMeta = ListMeta.of(meta);
  if (name)
    value = pick(obj, name);
  let text;
  if (value == null)
    text = nullFormat;
  else
    text = listMeta.labelOf(value);

  return wrap(text, value, classify, extra, className);
}

/**
 * A function that renders a value.
 *
 * @param {string} text - The stringified value.
 * @param {*} [value] - The original value (before stringification).
 * @param {string | :~ValueClassifier} [classify] - The style key to use, or a function that returns the style key.
 * @param {*} [extra] - The extra data passed to the value component.
 * @param {?string} [className] - The optional CSS class name to use.
 * @callback :~ValueRenderFunction
 */

/**
 * Determines the style key associated with a value.
 *
 * @param {*} value - The value to classify.
 * @returns {string} - The style key.
 * @callback :~ValueClassifier
 */

/**
 * Stringifies a value.
 *
 * @param {*} value - The value to convert.
 * @returns {string} The stringified value.
 * @callback :.FormattedValue~Stringifier
 */

/**
 * Converts a value.
 *
 * @param {*} value - The value to convert.
 * @returns {*} The converted value.
 * @callback :.FormattedValue~Converter
 */
