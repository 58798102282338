import GraphType from './graph-type.js';

const bars = [ 'bar-0', 'bar-1', 'bar-2', 'bar-3' ];

// ----------------------------------------------------------------------------
//    BarGraph
// ----------------------------------------------------------------------------

export default class BarGraph extends GraphType
{
  constructor(state)
  {
    super(state);

    this.indicatorCallback = this.meta.indicator ?? null;
    this.indicatorMargin = this.indicatorCallback ? 12 : 0;
    this.indicatorSpacing = 4;
    this.colorCallback = this.meta.barColorProvider ?? null;
  }

  draw(styles, context)
  {
    let state = this.state;
    let count = state.sampleCount;
    if (count == 0)
      return;

    let plotArea = state.plotArea;
    let dx = plotArea.width / count, barWidth = dx * 3 / 4;
    for (let index = 0, x = plotArea.left + (dx - barWidth) / 2; index < count; ++ index, x += dx)
    {
      let pos = (index + state.position) % count;
      let barIndex = 0;
      for (let data of state.datasets)
      {
        let value = data[pos];
        if (value != null)
        {
          let barHeight = (value - state.axis.minimum) * state.yscale;
          styles.using(context, bars[barIndex] ?? 'bar', context =>
          {
            if (this.colorCallback !== null)
              context.fillStyle = this.colorCallback(value, state.props[barIndex]);
            context.fillRect(x, plotArea.bottom - barHeight, barWidth, barHeight);
          });
        }
        if (this.indicatorCallback)
        {
          let indicator = this.indicatorCallback(this.plot.data, pos);
          if (typeof indicator === 'object' && indicator !== null)
          {
            let { shape='triangle', style='minor-indicator' } = indicator;
            styles.using(context, style, context =>
            {
              let size = Math.min(barWidth, this.indicatorMargin - this.indicatorSpacing);
              let x0 = plotArea.left + index * dx, x1 = x0 + dx / 2;
              let y0 = plotArea.bottom + this.indicatorSpacing, y1 = y0 + size / 2;
              context.beginPath();
              if (shape === 'triangle')
              {
                context.moveTo(x1, y0);
                context.lineTo(x1 + size / 2, y0 + size);
                context.lineTo(x1 - size / 2, y0 + size);
                context.lineTo(x1, y0);
              }
              else if (shape === 'circle')
              {
                context.arc(x1, y1, size / 2, 0, 2 * Math.PI);
              }
              context.fill();
            });
          }
        }
        ++ barIndex;
      }
    }
  }

  getViewX(index)
  {
    let x = super.getViewX(index);
    if (x < 0)
      return x;
    return x + this.state.xscale / 2;
  }

  adjustMargins(plotArea)
  {
    if (this.state.meta.indicator)
      plotArea.margin(0, 0, 0, this.indicatorMargin);
  }
}
