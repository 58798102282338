import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { typeOf } from '@ocsoft/sync-object';
import { Frame } from '../frame.js';

import { faQuestion as widgetIcon } from '@fortawesome/pro-solid-svg-icons';

import styles from './unknown.css';

export default function UnknownDevice({ device })
{
  return (
    <Frame device={device} icon={widgetIcon}>
      <div styleName="center">(No widget for type&nbsp;<b>{ typeOf(device) }</b>)</div>
    </Frame>
  );
}
