import GraphType from './graph-type.js';

const lineStyles = [ 'line-0', 'line-1', 'line-2', 'line-3' ];

// ----------------------------------------------------------------------------
//    MultilineGraph
// ----------------------------------------------------------------------------

export default class MultilineGraph extends GraphType
{
  constructor(state)
  {
    super(state);

    this._lineStyleIndex = 0;
  }

  draw(styles, context)
  {
    const state = this.state,
          plotArea = state.plotArea;

    this.drawVerticalGridLines(styles, context);

    this._lineStyleIndex = 0;
    this._drawPlots(styles, context, state.axis, state.yscale, state.datasets);
    if (state.rightDatasets) {
      this._drawPlots(styles, context, state.rightAxis, state.yscaleRight, state.rightDatasets);
    }

    this.drawMidnightGridLine(styles, context);
  }

  _drawPlots(styles, context, axis, yscale, datasets)
  {
    const plotArea = this.state.plotArea;
    for (const dataset of datasets)
    {
      // Build the plot path.
      context.beginPath();
      let first = true;
      const bottom = plotArea.bottom;
      for (let index = 0, x = plotArea.left; index < dataset.length; ++ index, x += this.state.xscale)
      {
        // Little hack to make last datapoint line up with the final vertical gridline.
        if (index === dataset.length - 1)
          x += this.state.xscale;
        const value = dataset[(index + this.state.position) % dataset.length];
        if (value !== null)
        {
          const y = bottom - (value - axis.minimum) * yscale;
          if (first)
          {
            context.moveTo(x, y);
            first = false;
          }
          else
            context.lineTo(x, y);
        }
      }

      // Draw the plot line.
      const style = lineStyles[this._lineStyleIndex];
      styles.using(context, style, context => context.stroke());
      this._lineStyleIndex = (this._lineStyleIndex + 1) % lineStyles.length;

      // Fill below the plot line.
      if (styles.value?.[style]?.fillStyle) {
        const lineWidth = styles.value?.[style]?.lineWidth ?? 1;
        context.save();
        context.translate(0, lineWidth);
        context.lineTo(plotArea.right, plotArea.bottom - lineWidth);
        context.lineTo(plotArea.left, plotArea.bottom - lineWidth);
        context.closePath();
        styles.using(context, style, context => context.fill());
        context.restore();
      }
    }
  }
}
