import React, { useMemo } from 'react';
import { execute } from '@ocsoft/sync-object';
import { useSyncList, useSorter } from '@ocsoft/sync-react';
import { Icon, useSearch, useBusy } from '@ocsoft/paper';
import { PageContent } from '@ocsoft/chassis';
import { FaultBox } from './faults.js';

import { faListAlt as sceneIcon } from '@fortawesome/pro-solid-svg-icons';

import styles from './scene-page.css';

// -----------------------------------------------------------------------------
//    ScenePage
// -----------------------------------------------------------------------------

export default function ScenePage()
{
  const scenes = useInteractiveScenes();

  return (
    <PageContent>
      <FaultBox />
      <div styleName="root">
        { scenes.map(scene => <Scene key={scene.uuid} obj={scene} />) }
      </div>
    </PageContent>
  );
}

function Scene({ obj })
{
  const [ busy, setBusy, prompt ] = useBusy();

  const toggle = () =>
  {
    if (! busy)
      setBusy(Promise.all([ execute(obj, 'toggle'), new Promise(resolve => setTimeout(resolve, 200)) ]));
  };

  return (
    <div styleName={obj.active ? 'active-scene' : 'scene'}>
      <div styleName={busy ? 'busy-inner' : 'inner'} onClick={toggle}>
        <Icon icon={obj.icon || sceneIcon} styleName="icon" />
        <div styleName="name">{ obj.name }</div>
      </div>
    </div>
  );
}

function useInteractiveScenes()
{
  const [ allScenes ] = useSyncList("/api/objects/scene");
  const found = useSearch();
  const sorter = useSorter('name');

  return useMemo(() =>
  {
    const scenes = allScenes.filter(scene => scene.interactive && found(scene.name));
    scenes.sort(sorter);
    return scenes;
  }, [ allScenes, found ]);
}
