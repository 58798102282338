// ----------------------------------------------------------------------------
//    Styles
// ----------------------------------------------------------------------------

export default class Styles
{
  constructor(value)
  {
    this.value = value;
  }

  static of(value)
  {
    if (value instanceof Styles)
      return value;
    return new Styles(value);
  }

  using(context, name, fn)
  {
    context.save();
    const style = this.value?.[name];
    if (style)
    {
      if (style.font !== undefined)
        context.font = style.font;
      if (style.strokeStyle !== undefined)
        context.strokeStyle = style.strokeStyle;
      if (style.fillStyle !== undefined)
        context.fillStyle = style.fillStyle;
      if (style.dashPattern !== undefined)
        context.setLineDash(style.dashPattern);
      if (style.textAlign !== undefined)
        context.textAlign = style.textAlign;
      if (style.textBaseline !== undefined)
        context.textBaseline = style.textBaseline;
      if (style.lineWidth !== undefined)
        context.lineWidth = style.lineWidth;
    }
    const result = fn(context, style);
    context.restore();
    return result;
  }
}
