/** @module chassis */

import React from 'react';
import { Icon, usePrompt } from '@ocsoft/paper';

import styles from './header-drop-icon.css';

// -----------------------------------------------------------------------------
//    HeaderDropIcon
// -----------------------------------------------------------------------------

/**
 * A header bar icon that displays a {@link :paper~Prompt#popover} when clicked.
 *
 * This component is typically used for drop-down menus.
 *
 * @prop {Object | string} icon - The imported Font Awesome icon object or SVG document string to display.
 * @prop {:paper~Element} children - The content of the window displayed when the user clicks the icon.
 * @component
 */
export function HeaderDropIcon({ icon, children })
{
  const prompt = usePrompt();

  const showMenu = evt => prompt.popover(children, evt.currentTarget, { flavor: 'dark' });

  return (
    <div styleName="icon" onClick={showMenu}>
      <Icon icon={icon} />
    </div>
  );
}
