import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { execute } from '@ocsoft/sync-object';
import { Frame } from '../frame.js';
import { Button } from '../button.js';
import { Alternator, PrimaryView, View, Menu, MenuItem } from '../alternator.js';

import { faShield as widgetIcon, faSensorAlert as notReadyIcon } from '@fortawesome/pro-duotone-svg-icons';
import { faBars as menuIcon } from '@fortawesome/pro-solid-svg-icons';
import { faCheckCircle as okIcon, faInfoCircle as infoIcon, faHome as stayIcon, faLockAlt as armedIcon,
         faSiren as alarmIcon, faBell as chimeIcon, faFireSmoke as fireIcon, faHouseLeave as exitIcon,
         faHouseReturn as entryIcon, faExclamationCircle as alertIcon } from '@fortawesome/pro-regular-svg-icons';

import styles from './alarm-partition.css';

const statusTable =
[
  { key: 'ready',         predicate: s => s.ready && ! s.armed, icon: okIcon,     css: 'ok',      label: "Ready to Arm"       },
  { key: 'armed',         predicate: s => s.armed,              icon: armedIcon,  css: 'info',    label: "System Armed"       },
  { key: 'stay',          predicate: s => s.stay,               icon: stayIcon,   css: 'info',    label: "Stay Mode"          },
  { key: 'exit',          predicate: s => s.exit,               icon: exitIcon,   css: 'warning', label: "Exit Timer"         },
  { key: 'entry',         predicate: s => s.entry,              icon: entryIcon,  css: 'warning', label: "Entry Timer"        },
  { key: 'tripped',       predicate: s => s.tripped,            icon: infoIcon,   css: 'warning', label: "Zone(s) Tripped"    },
  { key: 'alarm',         predicate: s => s.alarm,              icon: alarmIcon,  css: 'danger',  label: "Alarm in Progress!" },
  { key: 'fire',          predicate: s => s.fire,               icon: fireIcon,   css: 'danger',  label: "Fire Alarm!"        },
  { key: 'power',         predicate: s => ! s.power,            icon: alertIcon,  css: 'warning', label: "Power Failure"      },
  { key: 'bypassed',      predicate: s => s.bypassed,           icon: infoIcon,   css: 'info',    label: "Zone(s) Bypassed"   },
  { key: 'tamper',        predicate: s => s.tamper,             icon: alertIcon,  css: 'danger',  label: "Tamper Detected"    },
  { key: 'trouble',       predicate: s => s.trouble,            icon: infoIcon,   css: 'warning', label: "Trouble Detected"   },
  { key: 'chime',         predicate: s => s.chime,              icon: chimeIcon,  css: 'info',    label: "Chime Mode"         }
];

// ---------------------------------------------------------------------------
//    AlarmPartition (Widget)
// ---------------------------------------------------------------------------

export default function AlarmPartition({ device })
{
  const [ currentId, setCurrentId ] = useState(null);
  const armed = device.status.armed;

  return (
    <Frame device={device} icon={widgetIcon}>
      <div styleName="root">
        <Alternator currentId={currentId} setCurrentId={setCurrentId}>
          <PrimaryView justify="space-between">
            <div styleName="left">
              {
                statusTable.filter(entry => entry.predicate(device.status))
                  .map(entry => <StatusLine key={entry.key} entry={entry} />)
              }
            </div>
            <div styleName="right">
              <Button width="50px" onClick={() => setCurrentId('actions')}><FontAwesomeIcon icon={menuIcon} /></Button>
            </div>
          </PrimaryView>
          <View id="actions">
            <Menu>
              { ! armed && <MenuItem type="danger" handler={() => execute(device, 'arm-stay')}>Stay</MenuItem> }
              { ! armed && <MenuItem type="danger" handler={() => execute(device, 'arm-away')}>Away</MenuItem> }
              { armed && <MenuItem type="danger" handler={() => execute(device, 'disarm')}>Disarm</MenuItem> }
              <MenuItem handler={() => execute(device, 'toggle-chime')}>Chime</MenuItem>
            </Menu>
          </View>
        </Alternator>
      </div>
    </Frame>
  );
}

function StatusLine({ entry })
{
  return (
    <div styleName="status-line">
      <FontAwesomeIcon icon={entry.icon} className={styles[entry.css]} />
      <span styleName="status-label">{ entry.label }</span>
    </div>
  );
}
