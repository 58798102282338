import React from 'react';
import { Router, Redirect } from '@reach/router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem } from '@ocsoft/paper';
import { Application, PageIcon, PageSelector, CommonHeader, HeaderDropIcon,
         SearchBox, MenuLinkItem, render } from '@ocsoft/chassis';
import WidgetPage from './widget-page.js';
import ScenePage from './scene-page.js';
import PasswordPage from './password-page.js';

import { faStar as favoritesIcon, faEye as activeIcon, faGlobe as allIcon,
         faBars as menuIcon, faListAlt as sceneIcon } from '@fortawesome/pro-solid-svg-icons';

import styles from './main.css';

// -----------------------------------------------------------------------------
//    Application
// -----------------------------------------------------------------------------

render(
  <Application withAuthentication>
    <PageSelector>
      <PageIcon icon={favoritesIcon} label="Favorites" path="/widgets/favorites" />
      <PageIcon icon={activeIcon} label="Active" path="/widgets/active" />
      <PageIcon icon={allIcon} label="All" path="/widgets/all" />
      <PageIcon icon={sceneIcon} label="Scenes" path="/scenes" />
    </PageSelector>
    <CommonHeader>
      <HeaderDropIcon icon={menuIcon}>
        <MainMenu />
      </HeaderDropIcon>
      <SearchBox />
    </CommonHeader>
    <div styleName="content">
      <Router>
        <Redirect from="/" to="widgets/favorites" />
        <WidgetPage path="/widgets" />
        <WidgetPage path="/widgets/:mode" />
        <ScenePage path="/scenes" />
        <PasswordPage path="/password" />
      </Router>
    </div>
  </Application>
);

function MainMenu()
{
  return (
    <Menu minWidth="200px">
      <MenuLinkItem path="/password">Change My Password</MenuLinkItem>
    </Menu>
  );
}
