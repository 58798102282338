/** @module chassis */

import React, { useState } from 'react';
import { execute } from '@ocsoft/sync-object';
import { Icon, IconButton, usePrompt, usePromptWindow } from '@ocsoft/paper';

import { faUserTag as fallbackIcon, faCarGarage as garageIcon, faLockAlt as alarmIcon,
         faTimes as closeIcon } from '@fortawesome/pro-solid-svg-icons';

import styles from './tag-window.css';

// -----------------------------------------------------------------------------
//    TagWindow
// -----------------------------------------------------------------------------

/**
 * A window that allows a radio tag to be armed or disarmed.
 *
 * @prop {Object} tag - The tag object.
 * @example
 * const prompt = usePrompt();
 * prompt.modal(<TagWindow tag={tag} />);
 *
 * @component
 */
export function TagWindow({ tag })
{
  const [ garage, setGarage ] = useState(tag.options?.garage ?? false);
  const [ alarm, setAlarm ] = useState(tag.options?.alarm ?? false);
  const prompt = usePrompt();
  const window = usePromptWindow();

  const armed = tag.status !== 'present' && tag.status !== 'absent';
  const done = () => window.close();
  const perform = async verb =>
  {
    window.close();
    try
    {
      await execute(tag, verb, { garage, alarm });
    }
    catch (err)
    {
      prompt.error(err);
    }
  };
  const arm = () => perform('exit');
  const disarm = () => perform('cancel');

  return (
    <div styleName="frame">
      <div styleName="title">
        <Icon icon={tag.icon || fallbackIcon} styleName="tag-icon" />
        <div styleName="title-text">{ tag.name }</div>
        <IconButton icon={closeIcon} styleName="close-icon" onClick={done} />
      </div>
      <div styleName="options">
        <Option icon={garageIcon} label={garage ? "Use garage" : "No garage action"} select={garage} disabled={!tag.door}
                onClick={() => setGarage(! garage)}/>
        <Option icon={alarmIcon} label={alarm ? "Arm alarm": "No alarm"} select={alarm} disabled={!tag.partition}
                onClick={() => setAlarm(! alarm)}/>
      </div>
      <div styleName="actions">
        { ! armed && <div styleName="action" onClick={arm}>Exit With Selected Options</div> }
        { armed && <div styleName="disarm" onClick={disarm}>Cancel</div> }
      </div>
    </div>
  );
}

function Option({ icon, label, select, disabled, onClick })
{
  return (
    <div styleName={ disabled ? 'disabled-option' : select ? 'select-option' : 'option'} onClick={disabled ? null : onClick}>
      <Icon icon={icon} styleName="option-icon" />
      <div styleName="option-label">{ label }</div>
    </div>
  );
}
