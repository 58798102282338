import { isa } from '@ocsoft/sync-object';
import Registry from '../registry.js';
import { getCap } from '../device-util.js';
import WeatherStation from './wx-station.js';
import TemperatureSensor from './temperature-sensor.js';
import CurrentSensor from './current-sensor.js';
import Thermostat from './thermostat.js';

// -----------------------------------------------------------------------------
//    GraphRegistry
// -----------------------------------------------------------------------------

class GraphRegistry extends Registry
{
  constructor()
  {
    super(
    {
      'temperature-sensor':           TemperatureSensor,
      'current-sensor':               CurrentSensor,
      'thermostat':                   Thermostat,
      'wx-station':                   WeatherStation
    });
  }

  supports(device)
  {
    return (isa(device, 'temperature-sensor') || isa(device, 'current-sensor') || isa(device, 'wx-station')) && getCap(device, 'plot');
  }
}

export default new GraphRegistry();
